{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2022                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.Controls;

interface

uses
  Classes, WEBLib.Graphics, Types, SysUtils, Web, JS;

const
  VK_CANCEL = 3;
  VK_BACK = 8;
  VK_TAB = 9;
  VK_RETURN = 13;
  VK_SHIFT = 16;
  VK_CONTROL = 17;
  VK_MENU = 18;
  VK_PAUSE = 19;
  VK_CAPITAL = 20;
  VK_ESCAPE = 27;
  VK_SPACE = 32;
  VK_PRIOR = 33;
  VK_NEXT = 34;
  VK_END = 35;
  VK_HOME = 36;
  VK_LEFT = 37;
  VK_UP = 38;
  VK_RIGHT = 39;
  VK_DOWN = 40;
  VK_PRINT = 42;
  VK_SNAPSHOT = 44;
  VK_INSERT = 45;
  VK_DELETE = 46;
  VK_HELP = 47;
  VK_LWIN = 91;
  VK_RWIN = 92;
  VK_NUMPAD0 = 96;
  VK_NUMPAD1 = 97;
  VK_NUMPAD2 = 98;
  VK_NUMPAD3 = 99;
  VK_NUMPAD4 = 100;
  VK_NUMPAD5 = 101;
  VK_NUMPAD6 = 102;
  VK_NUMPAD7 = 103;
  VK_NUMPAD8 = 104;
  VK_NUMPAD9 = 105;
  VK_MULTIPLY = 106;
  VK_ADD = 107;
  VK_SEPARATOR = 108;
  VK_SUBTRACT = 109;
  VK_DECIMAL = 110;
  VK_DIVIDE = 111;
  VK_F1 = 112;
  VK_F2 = 113;
  VK_F3 = 114;
  VK_F4 = 115;
  VK_F5 = 116;
  VK_F6 = 117;
  VK_F7 = 118;
  VK_F8 = 119;
  VK_F9 = 120;
  VK_F10 = 121;
  VK_F11 = 122;
  VK_F12 = 123;
  VK_F13 = 124;
  VK_F14 = 125;
  VK_F15 = 126;
  VK_F16 = 127;
  VK_F17 = 128;
  VK_F18 = 129;
  VK_F19 = 130;
  VK_F20 = 131;
  VK_F21 = 132;
  VK_F22 = 133;
  VK_F23 = 134;
  VK_F24 = 135;
  VK_NUMLOCK = 144;

  WHEEL_DELTA = 120;

type
  TCursor = type Integer;

const
  crDefault     = 0;
  crNone        = 1;
  crArrow       = 2;
  crCross       = 3;
  crIBeam       = 4;
  crSize        = 5;
  crSizeNESW    = 6;
  crSizeNS      = 7;
  crSizeNWSE    = 8;
  crSizeWE      = 9;
  crUpArrow     = 10;
  crHourGlass   = 11;
  crDrag        = 12;
  crNoDrop      = 13;
  crHSplit      = 14;
  crVSplit      = 15;
  crMultIDrag   = 16;
  crSQLWait     = 17;
  crNo          = 18;
  crAppStart    = 19;
  crHelp        = 20;
  crHandPoint   = 21;
  crSizeAll     = 22;

  CSSBackground = 'background-color';
  CSSZIndex = 'z-index';

type
  TCSSCodeManager = class;
  Single = Double;
  TDragState = (dsDragEnter, dsDragLeave, dsDragMove);
  TDragMode = (dmManual, dmAutomatic);
  TDragKind = (dkDrag, dkDock);
  TDragObject = class(TObject);

  TAlign = (alNone, alTop, alBottom, alLeft, alRight, alClient, alCustom);
  TMouseButton = (mbLeft, mbRight, mbMiddle);
  TBorderStyle = (bsNone, bsSingle);
  TSizeStyle = (ssPercent, ssAbsolute, ssAuto);
  TScrollStyle = (ssNone, ssHorizontal, ssVertical, ssBoth);
  TStyleElements = set of (seFont, seClient, seBorder);

  TAnchorKind = (akLeft, akTop, akRight, akBottom);
  TAnchors = set of TAnchorKind;

  TMaterialGlyph = string;

  TElementClassName = type string;
  TElementID = type string;
  TElementFont = (efProperty, efCSS);
  TElementPosition = (epAbsolute, epRelative, epIgnore);

  TElementEvent = (eeClick, eeMouseDown, eeMouseUp, eeMouseMove, eeDblClick, eeKeyPress, eeKeyDown, eeKeyUp);
  TEventPropagation = set of TElementEvent;

  TTextDirection = (tdDefault, tdLeftToRight, tdRightToLeft, tdInherit);

  TBevelCut = (bvNone, bvLowered, bvRaised, bvSpace);
  TBevelEdge = (beLeft, beTop, beRight, beBottom);
  TBevelEdges = set of TBevelEdge;
  TBevelKind = (bkNone, bkTile, bkSoft, bkFlat);

  TMaterialGlyphType = (mgNormal, mgOutlined, mgTwoTone, mgRound, mgSharp);

  TJSXMLHttpRequestRecord = record
    req: TJSXMLHttpRequest;
  end;
  TJSEventRecord = record
    event: TEventListenerEvent;
  end;

  THTTPResponseEvent = procedure(Sender: TObject; AResponse: string) of object;
  THTTPRequestResponseEvent = procedure(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string) of object;
  THTTPAbortEvent = procedure(Sender: TObject) of object;
  THTTPErrorEvent = procedure(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; Event: TJSEventRecord; var Handled: boolean) of object;

  TJSHTMLElementRecord = record
    element: TJSHTMLElement;
  end;

  TJSObjectRecord = record
    jsobject: TJSObject;
  end;

  TJSArrayRecord = record
    jsarray: TJSArray;
  end;

  TJSArrayBufferRecord = record
    jsarraybuffer: TJSArrayBuffer;
  end;

  TDragSourceObject = class(TObject)
  private
    FJSEvent: TJSEvent;
    FObject: TObject;
  public
    property Event: TJSEvent read FJSEvent write FJSEvent;
    property &Object: TObject read FObject write FObject;
  end;

  TNotifyEvent = procedure(Sender: TObject) of object;
  TMouseEvent = procedure(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X,Y: Integer) of object;
  TMouseWheelEvent = procedure(Sender: TObject; Shift: TShiftState; WheelDelta: Integer; MousePos: TPoint; var Handled: Boolean) of object;
  TMouseWheelUpDownEvent = procedure(Sender: TObject; Shift: TShiftState;
    MousePos: TPoint; var Handled: Boolean) of object;
  TMouseMoveEvent = procedure(Sender: TObject; Shift: TShiftState; X,Y: Integer) of object;
  TKeyEvent = procedure(Sender: TObject; var AKey: Word; Shift: TShiftState) of object;
  TKeyPressEvent = procedure(Sender: TObject; var AChar: Char) of object;
  TTouchEvent = procedure(Sender: TObject; X,Y: integer) of object;
  TBiDiMode = (bdLeftToRight, bdRightToLeft, bdRightToLeftNoAlign, bdRightToLeftReadingOnly);

  TDragOverEvent = procedure(Sender, Source: TObject; X, Y: Integer; State: TDragState; var Accept: Boolean) of object;
  TDragDropEvent = procedure(Sender, Source: TObject; X, Y: Integer) of object;
  TStartDragEvent = procedure(Sender: TObject; var DragObject: TDragObject) of object;
  TEndDragEvent = procedure(Sender, Target: TObject; X, Y: Integer) of object;
  TScreenshotEvent = procedure(Sender: TObject; ABitmap: TBitmap) of object;

  TControlStyleValue = (csAcceptsControls, csSetCaption);
  TControlStyle = set of TControlStyleValue;

  TMargins = class(TPersistent)
  private
    FOnChange: TNotifyEvent;
    FLeft, FTop, FRight, FBottom: Integer;
  protected
    procedure SetLeft(const Value: integer);
    procedure SetTop(const Value: integer);
    procedure SetRight(const Value: integer);
    procedure SetBottom(const Value: integer);
    procedure DoChange; virtual;
  public
    constructor Create; reintroduce;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    procedure Assign(Source: TPersistent); override;
  published
    property Left: Integer read FLeft write SetLeft default 3;
    property Top: Integer read FTop write SetTop default 3;
    property Right: Integer read FRight write SetRight default 3;
    property Bottom: Integer read FBottom write SetBottom default 3;
  end;

  TPadding = class(TPersistent)
  private
    FOnChange: TNotifyEvent;
    FLeft, FTop, FRight, FBottom: Integer;
  protected
    procedure SetLeft(const Value: integer);
    procedure SetTop(const Value: integer);
    procedure SetRight(const Value: integer);
    procedure SetBottom(const Value: integer);
    procedure DoChange; virtual;
  public
    constructor Create; reintroduce;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    procedure Assign(Source: TPersistent); override;
  published
    property Left: Integer read FLeft write SetLeft default 0;
    property Top: Integer read FTop write SetTop default 0;
    property Right: Integer read FRight write SetRight default 0;
    property Bottom: Integer read FBottom write SetBottom default 0;
  end;


  TPercentSize = single;

  TControl = class(TComponent)
  private
    FGotFocus: Boolean;
    FAcceptDrag: Boolean;
    FCaptureDown: Boolean;
    FMouseInsideLayer: Boolean;
    FLayer: TJSElement;
    FCaptured: Boolean;
    FControlCreated: Boolean;
    FUpdateCount: integer;
    FBlockUpdateElement: Boolean;
    FElement: TJSElement;
    FID: string;
    FNew: Boolean;
    FContainer: TJSElement;
    FElementEvent, FLastElementEvent: TJSEvent;
    FElementClassName: string;
    FColor: TColor;
    FFont: TFont;
    FParent, FPrevParent: TControl;
    FControls: array of TControl;
    FOnDragOver: TDragOverEvent;
    FOnDragDrop: TDragDropEvent;
    FOnStartDrag: TStartDragEvent;
    FonEndDrag: TEndDragEvent;
    FOnClick: TNotifyEvent;
    FOnDblClick: TNotifyEvent;
    FOnMouseDown: TMouseEvent;
    FOnMouseUp: TMouseEvent;
    FOnMouseMove: TMouseMoveEvent;
    FOnKeyDown: TKeyEvent;
    FOnKeyUp: TKeyEvent;
    FOnKeyPress: TKeyPressEvent;
    FOnEnter: TNotifyEvent;
    FOnExit: TNotifyEvent;
    FEnabled: Boolean;
    FHint: string;
    FRole: string;
    FShowHint: Boolean;
    FTabOrder: Integer;
    FTabStop: Boolean;
    FVisible: Boolean;
    FWidth: Integer;
    FHeight: Integer;
    FTag: Integer;
    FAlign: TAlign;
    FAnchors: TAnchors;
    FAlignWithMargins: boolean;
    FIsAligning: boolean;
    FOnMouseEnter: TNotifyEvent;
    FOnMouseLeave: TNotifyEvent;
    FCursor: TCursor;
    FDoubleBuffered: Boolean;
    FControlStyle: TControlStyle;
    FMargins: TMargins;
    FOnMouseWheel: TMouseWheelEvent;
    FParentDoubleBuffered: Boolean;
    FParentColor: Boolean;
    FParentFont: Boolean;
    FParentBiDiMode: Boolean;
    FOnTouchMove: TTouchEvent;
    FOnTouchStart: TTouchEvent;
    FOnTouchEnd: TTouchEvent;
    FOnTouchCancel: TTouchEvent;
    FLinkTouchEvents: Boolean;
    FWidthStyle: TSizeStyle;
    FHeightStyle: TSizeStyle;
    FWidthPercent: TPercentSize;
    FHeightPercent: TPercentSize;
    FOrigRect: TRect;
    FOrigParentRect: TRect;
    FIsResizing: boolean;
    FShowFocus: boolean;
    FBorderWidth: integer;
    FOrigTop: integer;
    FOrigLeft: integer;
    FUpdateTopLeft: boolean;
    FEnablePropagation: Boolean;
    FParentShowHint: Boolean;
    FScriptLoaded: Boolean;
    FControlScriptCount: Integer;
    FControlScriptCountLoaded: Integer;
    FRequiredScripts: TStringList;
    FElementFont: TElementFont;
    FElementPosition: TElementPosition;
    FParentElement: TJSHTMLElement;
    FTagObject: TObject;
    FClipChildren: Boolean;
    FTextDirection: TTextDirection;
    FEventStopPropagation: TEventPropagation;
    FParentElementID: string;
    FOnResize: TNotifyEvent;
    FMouseMovePtr: pointer;
    FMouseDownPtr: pointer;
    FMouseUpPtr: pointer;
    FMouseEnterPtr: pointer;
    FMouseLeavePtr: pointer;
    FClickPtr: pointer;
    FDblClickPtr: pointer;
    FKeyDownPtr: pointer;
    FKeyUpPtr: pointer;
    FKeyPressPtr: pointer;
    FInputPtr: pointer;
    FTouchStartPtr: pointer;
    FTouchEndPtr: pointer;
    FTouchMovePtr: pointer;
    FTouchCancelPtr: pointer;
    FContextMenuPtr: pointer;
    FExitPtr: pointer;
    FEnterPtr: pointer;
    FWheelPtr: pointer;
    FLayerMouseEnterPtr: pointer;
    FLayerMouseLeavePtr: pointer;
    FScriptLoadedPtr: pointer;
    FDragStartPtr: pointer;
    FDragOverPtr: pointer;
    FDragEnterPtr: pointer;
    FDragLeavePtr: pointer;
    FDragEndPtr: pointer;
    FDragDropPtr: pointer;
    FChildOrder: integer;
    FAllowTouch: boolean;
    FCtl3D: boolean;
    FParentCtl3D: boolean;
    FWheelAccumulator: integer;
    FWheelMousePos: TPoint;
    FBiDiMode: TBiDiMode;
    FOnMouseWheelDown: TMouseWheelUpDownEvent;
    FOnMouseWheelUp: TMouseWheelUpDownEvent;
    FNoUserSelect: boolean;
    FEventsBound: boolean;
    FDragMode: TDragMode;
    FToolTip: JSValue;
    function GetControlsCount: Integer;
    function GetControls(Index: Integer): TControl;
    procedure SetVisible(AValue: Boolean);
    procedure SetHint(AValue: string);
    procedure SetShowHint(AValue: Boolean);
    procedure SetTabOrder(AValue: Integer);
    procedure SetTabStop(AValue: Boolean);
    procedure SetAlign(const Value: TAlign);
    procedure SetAlignWithMargins(const Value: boolean);
    function GetBoundsRect: TRect;
    procedure SetBoundsRect(const Value: TRect);
    procedure SetID(const Value: string);
    procedure SetMargins(const Value: TMargins);
    procedure SetAnchors(const Value: TAnchors);
    function GetElementEvent: TJSEvent;
    function GetClientOrigin: TPoint;
    procedure SetHeightStyle(const Value: TSizeStyle);
    procedure SetWidthStyle(const Value: TSizeStyle);
    procedure SetHeightPercent(const Value: TPercentSize);
    procedure SetWidthPercent(const Value: TPercentSize);
    procedure SetShowFocus(const Value: boolean);
    procedure SetBorderWidth(const Value: integer); virtual;
    function GetIsLinked: boolean;
    procedure SetScriptLoaded(const Value: boolean);
    procedure SetRequiredScripts(const Value: TStringList);
    procedure SetElementFont(const Value: TElementFont);
    procedure SetElementPosition(const Value: TElementPosition);
    procedure SetParentElement(const Value: TJSHTMLElement);
    procedure SetClipChildren(const Value: Boolean);
    function GetClientHeight: Integer;
    function GetClientWidth: Integer;
    procedure SetClientHeight(const Value: Integer);
    procedure SetClientWidth(const Value: Integer);
    procedure SetParentElementID(const Value: string);
    procedure SetChildOrderEx(const Value: integer);
    function GetRole: string;
    procedure SetRole(const Value: string);
    function GetContainer: TJSElement; virtual;
    function GetChildContainer: TJSElement; virtual;
    procedure SetParentFont(const Value: boolean);
    procedure SetParentColor(const Value: boolean);
    procedure SetDragMode(const Value: TDragMode);
  protected
    procedure SetWidth(AValue: Integer); virtual;
    procedure SetHeight(AValue: Integer); virtual;
    procedure SetWidthInt(AValue: Integer); virtual;
    procedure SetHeightInt(AValue: Integer); virtual;
    procedure SetEnabled(Value: Boolean); virtual;
    procedure RecreateCanvas; virtual;
    procedure VisibleChanging; virtual;
    procedure VisibleChanged; virtual;
    procedure ColorChanging; virtual;
    function GetWidth: Integer; virtual;
    function GetHeight: Integer; virtual;
    function GetOuterWidth: integer; virtual;
    function GetOuterHeight: integer; virtual;
    function GetDesignWidth: integer; virtual;
    function GetDesignHeight: integer; virtual;
    function GetDesignLeft: integer; virtual;
    function GetDesignTop: integer; virtual;
    function GetLeft: Integer; override;
    function GetTop: Integer; override;
    function HandleAllocated: Boolean; virtual;
    function CreateElement: TJSElement; virtual;
    procedure CreateChildElements(AContainer: TJSElement); virtual;
    function ContainerElement: TJSElement; virtual;
    function IsStructuralElement: boolean; virtual;
    function IsEnabled: boolean; virtual;
    function GetNewName: string; virtual;
    function IsInputControl: Boolean; virtual;
    procedure RecreateElement; virtual;
    procedure BindElement; virtual;
    procedure CreateInitialize; virtual;
    procedure DestroyControls; virtual;
    procedure ClearControls; virtual;
    function GetElementStyle: TJSCSSStyleDeclaration; virtual;
    procedure SetControlCursor(const Value: TCursor); virtual;
    procedure SetBiDiMode(const Value: TBiDiMode); virtual;
    function GetMouseEventButton(Event: TJSMouseEvent): TMouseButton; virtual;
    function GetMouseEventShiftState(Event: TJSMouseEvent): TShiftState; virtual;
    function GetKeyBoardEventShiftState(Event: TJSKeyBoardEvent): TShiftState; virtual;
    function GetMouseWheelEventShiftState(Event: TJSWheelEvent): TShiftState; virtual;
    function GetTouchEventShiftState(Event: TJSTouchEvent): TShiftState; virtual;
    function HandleDoClick(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDoWheel(Event: TJSWheelEvent): Boolean; virtual;
    function HandleDoDblClick(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDoMouseDown(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDoMouseUp(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDoMouseMove(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDoMouseLeave(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDoContextMenu(Event: TJSMouseEvent): Boolean; virtual;
    function LayerHandleDoMouseEnter(Event: TJSMouseEvent): Boolean; virtual;
    function LayerHandleDoMouseLeave(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDoMouseEnter(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDoKeyDown(Event: TJSKeyBoardEvent): Boolean; virtual;
    function HandleDoKeyUp(Event: TJSKeyBoardEvent): Boolean; virtual;
    function HandleDoKeyPress(Event: TJSKeyBoardEvent): Boolean; virtual;
    function HandleDoInput(Event: TJSInputEvent): Boolean; virtual;
    function HandleDoExit(Event: TEventListenerEvent): Boolean; virtual;
    function HandleDoEnter(Event: TJSFocusEvent): Boolean; virtual;
    function HandleDoTouchStart(Event: TJSTouchEvent): Boolean; virtual;
    function HandleDoTouchMove(Event: TJSTouchEvent): Boolean; virtual;
    function HandleDoTouchEnd(Event: TJSTouchEvent): Boolean; virtual;
    function HandleDoTouchCancel(Event: TJSTouchEvent): Boolean; virtual;
    function HandleDoDragStart(Event: TJSDragEvent): Boolean; virtual;
    function HandleDoDragOver(Event: TJSDragEvent): Boolean; virtual;
    function HandleDoDragEnter(Event: TJSDragEvent): Boolean; virtual;
    function HandledoDragLeave(Event: TJSDragEvent): Boolean; virtual;
    function HandleDoDragEnd(Event: TJSDragEvent): Boolean; virtual;
    function HandleDoDragDrop(Event: TJSDragEvent): Boolean; virtual;
    procedure HandleFontChanged(Sender: TObject); virtual;
    procedure ParentFontChanged;
    procedure DoExit; virtual;
    procedure DoEnter; virtual;
    procedure Click; virtual;
    procedure UpdateElement; virtual;
    procedure UpdateElementSize; virtual;
    procedure UpdateElementVisual; virtual;
    procedure UpdateElementData; virtual;
    procedure UpdateParent; virtual;
    procedure UpdateControlSize(AWidth, AHeight: integer); virtual;
    procedure InternalUpdateParent; virtual;
    procedure UpdateChildren(AControl: TControl); virtual;
    procedure PersistinHTML; virtual;
    procedure LoadFromHTML(const HTML: string); virtual;
    procedure InitFromHTML; virtual;
    function SaveState: string; virtual;
    procedure LoadState(AState: string); virtual;
    procedure DisableTab; virtual;
    procedure EnableTab; virtual;

    procedure SetElementClassName(AValue: string); virtual;
    procedure SetColor(AValue: TColor); virtual;
    procedure SetFont(AValue: TFont); virtual;
    procedure SetParent(AValue: TControl); virtual;
    procedure RegisterParent(AValue: TControl); virtual;
    procedure UnRegisterParent(AValue: TControl); virtual;
    function CanAcceptChild(AValue: TControl): TControl; virtual;

    procedure SetLeft(AValue: Integer); override;
    procedure SetTop(AValue: Integer); override;

    procedure MouseUp(Button: TMouseButton; Shift: TShiftState; X,Y: Integer); virtual;
    procedure MouseDown(Button: TMouseButton; Shift: TShiftState; X,Y: Integer); virtual;
    procedure MouseMove(Shift: TShiftState; X,Y: Integer); virtual;

    procedure TouchStart(X,Y: Integer); virtual;
    procedure TouchMove(X,Y: Integer); virtual;
    procedure TouchEnd(X,Y: Integer); virtual;
    procedure TouchCancel(X,Y: Integer); virtual;

    procedure DoMouseEnter; virtual;
    procedure DoMouseLeave; virtual;
    procedure MouseWheel(Shift: TShiftState; WheelDelta: Integer; var Handled: Boolean); virtual;
    function DoMouseWheelDown(Shift: TShiftState; MousePos: TPoint): Boolean; virtual;
    function DoMouseWheelUp(Shift: TShiftState; MousePos: TPoint): Boolean; virtual;
    procedure DblClick; virtual;
    procedure KeyDown(var Key: Word; Shift: TShiftState); virtual;
    procedure KeyPress(var Key: Char); virtual;
    procedure KeyUp(var Key: Word; Shift: TShiftState); virtual;
    procedure SetFocus; virtual;
    procedure DoMarginsChanged(Sender: TObject); virtual;
    procedure DoRealign; virtual;
    procedure DoBoundsChange; virtual;
    procedure DoEndDrag(Target: TObject; X, Y: Integer); virtual;
    procedure DoStartDrag(var DragObject: TDragObject); virtual;
    procedure DragOver(Source: TObject; X, Y: Integer; State: TDragState;
      var Accept: Boolean); virtual;
    function IsFocused: Boolean; virtual;
    function Focused: Boolean; virtual;
    function GetID: string; virtual;
    function GetElementHandle: TJSHTMLElement; virtual;
    function GetElementBindHandle: TJSEventTarget; virtual;
    function GetElement: TJSElement;
    function GetKeyCode(AValue: string; IgnoreCase: boolean = true): Integer;
    function GetKeyChar(AValue: string): Char;
    function IsKeyCharacter(AValue: string): Boolean;
    function GetClientRect: TRect; virtual;
    property Container: TJSElement read GetContainer write FContainer;
    property ChildContainer: TJSElement read GetChildContainer;
    property ElementBindHandle: TJSEventTarget read GetElementBindHandle;
    property Element: TJSElement read GetElement;

    procedure CreateControl; virtual;
    procedure ClearMethodPointers; virtual;
    procedure GetMethodPointers; virtual;
    function EventsBound: boolean;
    procedure BindEvents; virtual;
    procedure UnbindEvents; virtual;
    procedure AlignControls(AControl: TControl; var ARect: TRect); virtual;
    procedure AlignControl(AControl: TControl); virtual;
    procedure InitAnchoring; virtual;
    function AnchoringInitialized: boolean;
    procedure InitScript; virtual;
    procedure Realign; virtual;
    procedure Loaded; override;
    procedure InternalResize; virtual;
    procedure FontChanged; virtual;
    procedure DisposeOf;
    procedure ClearChildElements; virtual;

    property Color: TColor read FColor write SetColor;
    property ControlCreated: boolean read FControlCreated;
    property Font: TFont read FFont write SetFont;
    property ClientOrigin: TPoint read GetClientOrigin;
    property OrigRect: TRect read FOrigRect;
    property DragMode: TDragMode read FDragMode write SetDragMode default dmManual;

    property OnClick: TNotifyEvent read FOnClick write FOnClick;
    property OnResize: TNotifyEvent read FOnResize write FOnResize;
    property OnDblClick: TNotifyEvent read FOnDblClick write FOnDblClick;
    property ControlStyle: TControlStyle read FControlStyle write FControlStyle;
    procedure HookElement;
    procedure SetElementColor(AElement: TJSHTMLElement; AColor: TColor);
    procedure MoveElements(FromElement,ToElement: TJSElement); virtual;
    procedure MoveElementsAndFree(FromElement,ToElement: TJSElement); virtual;
    procedure CreateWithID(AID: string); virtual;
    procedure AddInstanceStyle(const css: string); virtual; overload;
    class procedure AddInstanceStyle(const id: string; const css: string); virtual; overload;
    procedure AddControlStyle(const css: string); virtual;
    procedure AddControlLink(const linkid, link: string); virtual;
    procedure AddControlScriptLink(const link: string); virtual;
    procedure AddControlScriptSource(const source: string); virtual;
    function AddRequiredScripts: boolean;
    procedure AddRequiredScript(const link: string); virtual;
    procedure ResetAnchoring; virtual;
    procedure UpdateSize; virtual;
    procedure UpdateAnchoring; virtual;
    procedure UpdateChildAnchoring; virtual;
    procedure SetElementPointer(AElement: TJSHTMLElement; ACursor: TCursor);
    function GetWebClassName: string; virtual;
    function GetCSSManager: TCSSCodeManager; virtual;
    function IsUpdating: boolean;
    function CanShowFocus: boolean; virtual;
    procedure RequiredScriptLoaded(Event: TJSEvent); virtual;
    procedure InjectCSS; virtual;
    procedure StartCapture; virtual;
    property LinkTouchEvents: Boolean read FLinkTouchEvents write FLinkTouchEvents;
    property OnTouchStart: TTouchEvent read FOnTouchStart write FOnTouchStart;
    property OnTouchMove: TTouchEvent read FOnTouchMove write FOnTouchMove;
    property OnTouchEnd: TTouchEvent read FOnTouchEnd write FOnTouchEnd;
    property OnTouchCancel: TTouchEvent read FOnTouchCancel write FOnTouchCancel;
    property ShowFocus: boolean read FShowFocus write SetShowFocus default false;
    property BorderWidth: integer read FBorderWidth write SetBorderWidth default 0;
    property ControlScriptCount: integer read FControlScriptCount write FControlScriptCount;
    property ControlScriptCountLoaded: integer read FControlScriptCountLoaded write FControlScriptCountLoaded;
    property RequiredScripts: TStringList read FRequiredScripts write SetRequiredScripts;
    property Role: string read GetRole write SetRole;
    property TextDirection: TTextDirection read FTextDirection write FTextDirection default tdDefault;
    function RequiredBaseURL: string; virtual;
    function GetStyle(css: string): string;
    procedure SetBoundsInt(X, Y, AWidth, AHeight: Integer); virtual;
    procedure MoveFirst(AControl: TControl); virtual;
    procedure MoveLast(AControl: TControl); virtual;
    property ParentElementHandle: TJSHTMLElement read FParentElement write FParentElement;
    property NoUserSelect: boolean read FNoUserSelect write FNoUserSelect;
    procedure EnableDrag; virtual;
    procedure DisableDrag; virtual;
    property OnDragOver: TDragOverEvent read FOnDragOver write FOnDragOver;
    property OnDragDrop: TDragDropEvent read FOnDragDrop write FOnDragDrop;
    property OnStartDrag: TStartDragEvent read FOnStartDrag write FOnStartDrag;
    property OnEndDrag: TEndDragEvent read FOnEndDrag write FOnEndDrag;
  public
    constructor Create(ID: string); virtual; overload;
    constructor Create(AOwner: TComponent); overload; override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    procedure DragDrop(Source: TObject; X, Y: Integer); virtual;
    procedure Resize; virtual;
    procedure Capture;
    procedure ApplyName;
    procedure ReleaseCapture;
    procedure BringToFront;
    procedure SendToBack;
    procedure Show;
    procedure Hide;
    procedure PreventDefault;
    procedure StopPropagation;
    procedure BeginDrag;
    procedure BeginUpdate; override;
    procedure EndUpdate; override;
    procedure Invalidate; virtual;
    procedure SetParentComponent(Value: TComponent); override;
    procedure SetBounds(X, Y, AWidth, AHeight: Integer); virtual;
    procedure XYToClient(X,Y: single; var AClientX, AClientY: single); virtual;
    procedure ClientToXY(AClientX, AClientY: single; var X,Y: single); virtual;
    procedure GetChildren(Proc: TGetChildProc; Root: TComponent); override;
    function Captured: Boolean; virtual;
    function MakeScreenshot: TBitmap; virtual;
    function ClientToScreen(const Point: TPoint): TPoint; virtual;
    function ScreenToClient(const Point: TPoint): TPoint; virtual;
    function CanFocus: Boolean; virtual;
    function HasHorzScrollBar: Boolean; virtual;
    function HasVertScrollBar: Boolean; virtual;
    property BiDiMode: TBiDiMode read FBiDiMode write SetBiDiMode default bdLeftToRight;
    property Ctl3D: boolean read FCtl3D write FCtl3D;
    property ParentCtl3D: boolean read FParentCtl3D write FParentCtl3D;
    property AllowTouch: boolean read FAllowTouch write FAllowTouch;
    property EventStopPropagation: TEventPropagation read FEventStopPropagation write FEventStopPropagation;
    property ChildOrder: integer read FChildOrder write SetChildOrderEx default 0;
    property Controls[Index: Integer]: TControl read GetControls;
    property ControlCount: Integer read GetControlsCount;
    property Parent: TControl read FParent write SetParent;
    property ParentElement: TJSHTMLElement read FParentElement write SetParentElement;
    property ParentElementID: string read FParentElementID write  SetParentElementID;
    property BoundsRect: TRect read GetBoundsRect write SetBoundsRect;
    property DoubleBuffered: Boolean read FDoubleBuffered write FDoubleBuffered default false;
    property ParentDoubleBuffered: Boolean read FParentDoubleBuffered write FParentDoubleBuffered default true;
    property ParentColor: boolean read FParentColor write SetParentColor default false;
    property ParentFont: boolean read FParentFont write SetParentFont default true;
    property ParentBiDiMode: boolean read FParentBiDiMode write FParentBiDiMode default true;
    property Align: TAlign read FAlign write SetAlign default alNone;
    property AlignWithMargins: boolean read FAlignWithMargins write SetAlignWithMargins default false;
    property ElementEvent: TJSEvent read GetElementEvent write FElementEvent;
    property ElementHandle: TJSHTMLElement read GetElementHandle;
    property ElementClassName: TElementClassName read FElementClassName write SetElementClassName;
    property ElementFont: TElementFont read FElementFont write SetElementFont default efProperty;
    property ElementPosition: TElementPosition read FElementPosition write SetElementPosition default epAbsolute;
    property ElementID: TElementID read GetID write SetID;
    property Margins: TMargins read FMargins write SetMargins;
    property ClientRect: TRect read GetClientRect;
    property EnablePropagation: Boolean read FEnablePropagation write FEnablePropagation default False;
    property ScriptLoaded: boolean read FScriptLoaded write SetScriptLoaded;
    property ClipChildren: Boolean read FClipChildren write SetClipChildren default True;

    property Anchors: TAnchors read FAnchors write SetAnchors default [akLeft, akTop];
    property Cursor: TCursor read FCursor write SetControlCursor default crDefault;
    property Enabled: Boolean read FEnabled write SetEnabled default true;
    property HeightStyle: TSizeStyle read FHeightStyle write SetHeightStyle default ssAbsolute;
    property Height: Integer read GetHeight write SetHeight;
    property HeightPercent: TPercentSize read FHeightPercent write SetHeightPercent default 100;
    property Hint: string read FHint write SetHint;
    property IsLinked: boolean read GetIsLinked;
    property ShowHint: Boolean read FShowHint write SetShowHint default false;
    property ParentShowHint: Boolean read FParentShowHint write FParentShowHint;
    property TabOrder: Integer read FTabOrder write SetTabOrder;
    property TabStop: Boolean read FTabStop write SetTabStop default true;
    property Tag: Integer read FTag write FTag default 0;
    property TagObject: TObject read FTagObject write FTagObject;
    property Visible: Boolean read FVisible write SetVisible default true;
    property WidthStyle: TSizeStyle read FWidthStyle write SetWidthStyle default ssAbsolute;
    property ClientHeight: Integer read GetClientHeight write SetClientHeight;
    property ClientWidth: Integer read GetClientWidth write SetClientWidth;
    property Width: Integer read GetWidth write SetWidth;
    property WidthPercent: TPercentSize read FWidthPercent write SetWidthPercent default 100;

    property OnMouseWheel: TMouseWheelEvent read FOnMouseWheel write FOnMouseWheel;
    property OnMouseWheelDown: TMouseWheelUpDownEvent read FOnMouseWheelDown write FOnMouseWheelDown;
    property OnMouseWheelUp: TMouseWheelUpDownEvent read FOnMouseWheelUp write FOnMouseWheelUp;

    property OnMouseDown: TMouseEvent read FOnMouseDown write FOnMouseDown;
    property OnMouseUp: TMouseEvent read FOnMouseUp write FOnMouseUp;
    property OnMouseMove: TMouseMoveEvent read FOnMouseMove write FOnMouseMove;
    property OnMouseEnter: TNotifyEvent read FOnMouseEnter write FOnMouseEnter;
    property OnMouseLeave: TNotifyEvent read FOnMouseLeave write FOnMouseLeave;
    //Note: On Android the OnKeyUp and OnKeyDown events do not return the correct KeyCode.
    //This is a known issue:
    //https://bugs.chromium.org/p/chromium/issues/detail?id=118639
    //https://stackoverflow.com/questions/36753548/keycode-on-android-is-always-229
    //Workaround: Use the Char parameter of the OnKeyPress event instead
    property OnKeyUp: TKeyEvent read FOnKeyUp write FOnKeyUp;
    property OnKeyDown: TKeyEvent read FOnKeyDown write FOnKeyDown;
    //
    //Note: On Android the OnKeyPress event is not triggered.
    //The OnInput event is used instead to trigger the OnKeyPress event.
    property OnKeyPress: TKeyPressEvent read FOnKeyPress write FOnKeyPress;
    //
    property OnEnter: TNotifyEvent read FOnEnter write FOnEnter;
    property OnExit: TNotifyEvent read FOnExit write FOnExit;
  published
//    property DesignSize: TPoint read FDesignSize write FDesignSize stored false;
//    property ExplicitLeft: integer read FExplicitLeft write FExplicitLeft stored false;
//    property ExplicitTop: integer read FExplicitTop write FExplicitTop stored false;
//    property ExplicitWidth: integer read FExplicitWidth write FExplicitWidth stored false;
//    property ExplicitHeight: integer read FExplicitHeight write FExplicitHeight stored false;
  end;

  TScrollBarKind = (sbHorizontal, sbVertical);

  TControlScrollBar = class(TPersistent)
  private
    FKind: TScrollBarKind;
    FControl: TControl;
  public
    constructor Create(AControl: TControl; AKind: TScrollBarKind);
    property Kind: TScrollBarKind read FKind;
    function IsScrollBarVisible: boolean;
    function ScrollPos: integer;
    function Range: integer;
  end;


  TWinControl = class(TControl)
  published
    property Cursor;
    property ElementClassName;
    property Enabled;
    property Height;
    property Hint;
    property Left;
    property ShowHint;
    property Tag;
    property Top;
    property Visible;
    property Width;

    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseEnter;
    property OnMouseLeave;
    property OnKeyUp;
    property OnKeyDown;
    property OnKeyPress;
    property OnEnter;
    property OnExit;
  end;

  TCustomControl = class(TWinControl)
  private
    FCaption: string;
    FPixelRatio: Single;
    FPainting: boolean;
    FCanvas: TCanvas;
    FElementCanvas: TJSHTMLCanvasElement;
    FBorderStyle: TBorderStyle;
    FBorderColor: TColor;
    FCustomBorder: boolean;
    FOnScreenShot: TScreenshotEvent;
    FBevelOuter: TBevelCut;
    FBevelWidth: Integer;
    FBevelCut: TBevelCut;
    FBevelEdges: TBevelEdges;
    FBevelKind: TBevelKind;
    function GetCanvas: TCanvas;
  protected
    procedure RenderDesigning(ACaption: string; AElement: TJSElement; AControl: TCustomControl; ADisplay: Boolean; AImage: string = '');
    function GetPixelRatio: Single;
    function GetContext: TJSCanvasRenderingContext2D; virtual;
    procedure RecreateCanvas; override;
    procedure SetBorderStyle(const AValue: TBorderStyle); virtual;
    procedure SetBorderColor(const AValue: TColor); virtual;
    procedure SetName(const NewName: TComponentName); override;
    procedure SetCaption(const AValue: string); virtual;
    procedure CreateControl; override;
    procedure Loaded; override;
    procedure UpdateElementVisual; override;
    function CreateElement: TJSElement; override;
    function GetCanvasHeightOffset: Integer; virtual;
    function GetCanvasWidthOffset: Integer; virtual;
    property BorderStyle: TBorderStyle read FBorderStyle write SetBorderStyle default bsSingle;
    property BorderColor: TColor read FBorderColor write SetBorderColor default clSilver;
    procedure BindElement; override;
    procedure BindEvents; override;
    procedure HandleScreenShot(Sender: TObject); virtual;
    procedure Paint; virtual;
    property CustomBorder: boolean read FCustomBorder write FCustomBorder;
    property Caption: string read FCaption write SetCaption;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    function MakeScreenShot: TBitmap; override;
    procedure Resize; override;
    procedure Invalidate; override;
    procedure SetFocus; override;
    function Focused: boolean; override;
    procedure EndUpdate; override;
    property Canvas: TCanvas read GetCanvas;
    property GraphicsContext:  TJSCanvasRenderingContext2D read GetContext;
    property ElementCanvas: TJSHTMLCanvasElement read FElementCanvas;
    property OnScreenshot: TScreenshotEvent read FOnScreenshot write FOnScreenshot;
    //dummy properties for FNC
    property BevelEdges: TBevelEdges read FBevelEdges write FBevelEdges;
    property BevelInner: TBevelCut read FBevelCut write FBevelCut;
    property BevelKind: TBevelKind read FBevelKind write FBevelKind;
    property BevelOuter: TBevelCut read FBevelOuter write FBevelOuter;
    property BevelWidth: Integer read FBevelWidth write FBevelWidth;
  end;

  TjQueryCustomControl = class(TWinControl)
  private
    FIsInitialized: boolean;
    procedure InitJQueryOnce;
  protected
    function CreateElement: TJSElement; override;
    procedure Loaded; override;
    procedure SetParent(AValue: TControl); override;
    function GetJQID: string;
    procedure InitJQuery; virtual;
  public
    procedure CreateInitialize; override;
  published
    property ElementPosition;
  end;

  TGraphicControl = class(TCustomControl);

  TScrollingGraphicControl = class(TCustomControl)
  protected
    function CreateElement: TJSElement; override;
  public
    procedure UpdateElement; override;
  end;

  TCustomHTMLDiv = class(TCustomControl)
  protected
    function CreateElement: TJSElement; override;
  end;

  TControlManager = class(TComponent)
  private
    FInstanceCount: integer;
  public
    constructor Create(AOwner: TComponent); override;
    function GetInstanceNumber: integer;
    procedure Reset;
  end;

  TCSSCodeFragment = class(TCollectionItem)
  private
    FControlClassname: string;
    FCSS: TStringList;
    procedure SetCSS(const Value: TStringList);
  public
    constructor Create(Collection: TCollection); override;
    destructor Destroy; override;
  published
    property ControlClassname: string read FControlClassname write FControlClassname;
    property CSS: TStringList read FCSS write SetCSS;
  end;

  TCSSCodeFragments = class(TOwnedCollection)
  private
    function GetItemEx(Index: integer): TCSSCodeFragment;
    procedure SetItemEx(Index: integer; const Value: TCSSCodeFragment);
  public
    constructor Create(AOwner: TComponent); reintroduce;
    function Add: TCSSCodeFragment; reintroduce;
    function Insert(Index: integer): TCSSCodeFragment; reintroduce;
    property Items[Index: integer]: TCSSCodeFragment read GetItemEx write SetItemEx;
  end;

  TCSSCodeManager = class(TComponent)
  private
    FCSSFragments: TCSSCodeFragments;
    procedure SetCSSFragments(const Value: TCSSCodeFragments);
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    function GetClassFragment(AClassname: string): TCSSCodeFragment;
    function GetClassCSS(AClassname: string): string;
  published
    property CSSFragments: TCSSCodeFragments read FCSSFragments write SetCSSFragments;
  end;

  ITMSFNCDataBinderBase = interface
    ['{778B63C9-34E3-4B65-A6B8-85E3EB1D17C3}']
    procedure DataBeginUpdate;
    procedure DataEndUpdate;
  end;

  ITMSFNCDataBinderGrid = interface(ITMSFNCDataBinderBase)
    ['{D23BDEAA-49B1-451A-9401-0D0D11A9957A}']
    procedure SetDataColumnCount(AValue: Integer);
    procedure SetDataRowCount(AValue: Integer);
    procedure ClearData;
    function GetDataRowCount: Integer;
    procedure SetDataValue(AColumn, ARow: Integer; AValue: string);
    procedure SetDataHeader(AColumn: Integer; AValue: string);
    procedure DataInsertRow(AInsertPosition: Integer);
  end;

function FindGlobalComponent(const Name: string): TComponent;
function GetMousePos: TPoint;
procedure GetCursorPos(var pt: TPoint);
function FindUniqueName(const Name: string): string;
function GetScrollBarHeight: integer;
function GetScrollBarWidth: integer;
procedure SetHTMLElementFont(AElement: TJSHTMLElement; AFont: TFont; UseCSS: boolean);
procedure SetHTMLElementColor(AElement: TJSHTMLElement; AColor: TColor; UseCSS: boolean);
function GetHTMLCursorName(ACursor: TCursor): string;
function GetHTMLElementAbsoluteRect(AElement: TJSHTMLElement): TRect;

function CursorToString(ACursor: TCursor): string;
function StringToCursor(const S: string): TCursor;
function IsAndroid: Boolean;
function GetMaterialClass(mg: TMaterialGlyphType): string;


implementation

uses
  WEBLib.Dialogs, WEBLib.Forms, Math, TypInfo, WEBLib.WebTools, WEBLib.Utils;

var
  ControlManager: TControlManager;
  FMouseX, FMouseY: Integer;
  FCursorX, FCursorY: Integer;
  ScrollBW, ScrollBH: Integer;
  DragObject: TObject;


const
  cStyleBkgColor = 'background-color';

function GetMaterialClass(mg: TMaterialGlyphType): string;
begin
  Result := 'material-icons';
  case mg of
  mgOutlined: Result := 'material-icons-outlined';
  mgTwoTone: Result := 'material-icons-two-tone';
  mgRound: Result := 'material-icons-round';
  mgSharp: Result := 'material-icons-sharp';
  end;
end;

procedure SetHTMLElementFont(AElement: TJSHTMLElement; AFont: TFont; UseCSS: boolean);
var
  s: string;
begin
  if UseCSS then
  begin
    AElement.style.removeProperty('font-family');
    AElement.style.removeProperty('font-style');
    AElement.style.removeProperty('font-weight');
    AElement.style.removeProperty('font-size');
    AElement.style.removeProperty('text-decoration');
  end
  else
  begin
    AElement.style.setProperty('font-family', AFont.Name);

    AElement.style.setProperty('font-style', 'normal');

    if fsBold in AFont.Style then
      AElement.style.setProperty('font-weight', 'bold')
    else
      AElement.style.setProperty('font-weight', '');

    if fsItalic in AFont.Style then
       AElement.style.setProperty('font-style', 'italic');

    s := '';

    if fsUnderline in AFont.Style then
      s := 'underline';

    if fsStrikeOut in AFont.Style then
    begin
      if s <> ''  then
         s := s + ' ';
       s:= s + 'line-through';
    end;

    if (s <> '') then
      AElement.style.setProperty('text-decoration', s)
    else
      AElement.style.removeProperty('text-decoration');

    AElement.style.setProperty('font-size', IntToStr(AFont.Size) + 'pt');
  end;
end;

procedure SetHTMLElementColor(AElement: TJSHTMLElement; AColor: TColor; UseCSS: boolean);
begin
  if UseCSS or (AColor = clNone) or (AColor = clWindow) then
    AElement.style.removeProperty(cStyleBkgColor)
  else
    AElement.style.setProperty(cStyleBkgColor, ColorToHTML(AColor));
end;

function GetHTMLCursorName(ACursor: TCursor): string;
begin
  Result := 'default';
  case ACursor of
    crDefault: Result :='default';
    crArrow: Result :='auto';
    crNone: Result :='none';
    crCross: Result :='crosshair';
    crIBeam: Result :='text';
    crSizeNESW: Result :='nesw-resize';
    crSizeNS: Result :='ns-resize';
    crSizeNWSE: Result :='nwse-resize';
    crSizeWE: Result :='ew-resize';
    crUpArrow: Result :='n-resize';
    crHourGlass: Result :='wait';
    crDrag: Result :='copy';
    crNoDrop: Result :='no-drop';
    crHSplit: Result :='col-resize';
    crVSplit: Result :='row-resize';
    crMultiDrag: Result :='copy';
    crSQLWait: Result :='progress';
    crNo: Result :='not-allowed';
    crAppStart: Result :='progress';
    crHelp: Result :='help';
    crHandPoint: Result :='pointer';
    crSizeAll,crSize: Result :='move';
  end;
end;

function CursorToString(ACursor: TCursor): string;
begin
  Result := 'crDefault';
  case ACursor of
    crDefault: Result :='crDefault';
    crArrow: Result :='crArrow';
    crNone: Result :='crNone';
    crCross: Result :='crCross';
    crSize: Result := 'ctSize';
    crIBeam: Result :='crIBeam';
    crSizeNESW: Result :='crSizeNESW';
    crSizeNS: Result :='crSizeNS';
    crSizeNWSE: Result :='crSizeNWSE';
    crSizeWE: Result :='crSizeWE';
    crUpArrow: Result :='crUpArrow';
    crHourGlass: Result :='crHourGlass';
    crDrag: Result :='crDrag';
    crNoDrop: Result :='crNoDrop';
    crHSplit: Result :='crHSplit';
    crVSplit: Result :='crVSplit';
    crMultiDrag: Result :='crMultiDrag';
    crSQLWait: Result :='crSQLWait';
    crNo: Result :='crNo';
    crAppStart: Result :='crAppStart';
    crHelp: Result :='crHelp';
    crHandPoint: Result :='crHandpoint';
    crSizeAll: Result :='crSizeAll';
  end;
end;

function StringToCursor(const S: string): TCursor;
var
  us: string;
begin
  Result := crDefault;
  us := Uppercase(s);
  if (us = 'CRDEFAULT') then Result := crDefault else
  if (us = 'CRARROW') then Result := crArrow else
  if (us = 'CRNONE') then Result := crNone else
  if (us = 'CRCROSS') then Result := crCross else
  if (us = 'CRSIZE') then Result := crSize else
  if (us = 'CRIBEAM') then Result := crIBeam else
  if (us = 'CRSIZENESW') then Result := crSizeNESW else
  if (us = 'CRSIZENS') then Result := crSizeNS else
  if (us = 'CRSIZENWSE') then Result := crSizeNWSE else
  if (us = 'CRSIZEWE') then Result := crSizeWE else
  if (us = 'CRUPARROW') then Result := crUpArrow else
  if (us = 'CRHOURGLASS') then Result := crHourGlass else
  if (us = 'CRDRAG') then Result := crDrag else
  if (us = 'CRNODROP') then Result := crNoDrop else
  if (us = 'CRHSPLIT') then Result := crHSplit else
  if (us = 'CRVSPLIT') then Result := crVSplit else
  if (us = 'CRMULTIDRAG') then Result := crMultiDrag else
  if (us = 'CRSQLWAIT') then Result := crSQLWait else
  if (us = 'CRNO') then Result := crNo else
  if (us = 'CRAPPSTART') then Result := crAppStart else
  if (us = 'CRHELP') then Result := crHelp else
  if (us = 'CRHANDPOINT') then Result := crHandpoint else
  if (us = 'CRSIZEALL') then Result := crSizeAll;
end;


function IsAndroid: Boolean;
var
  sagent: string;
begin
  {$IFDEF PAS2JS}
  asm
    sagent = navigator.userAgent;
  end;
  {$ENDIF}
  Result := (pos('Android', sagent) > 0);
end;

function GetMousePos: TPoint;
begin
  Result := Point(FMouseX, FMouseY);
end;

procedure GetCursorPos(var pt: TPoint);
begin
  pt.x := FCursorX;
  pt.y := FCursorY;
end;

function GetHTMLElementAbsoluteRect(AElement: TJSHTMLElement): TRect;
var
  s: string;

  function GetInt(AValue: string): integer;
  var
    e: integer;
  begin
    AValue := StringReplace(AValue, 'px','', [rfReplaceAll]);
    val(AValue, Result, e);
  end;

begin
  s := AElement.style.getPropertyValue('left');
  Result.left := GetInt(s);
  s := AElement.style.getPropertyValue('top');
  Result.top := GetInt(s);
  s := AElement.style.getPropertyValue('width');
  Result.right := Result.Left + GetInt(s);
  s := AElement.style.getPropertyValue('height');
  Result.bottom := Result.top + GetInt(s);
end;

function GetScrollBarWidth: integer;
var
  res: integer;
begin
  res := ScrollBW;

  if res = 0 then
  begin
    {$IFDEF PAS2JS}
    asm
      var outer = document.createElement("div");
      outer.style.visibility = "hidden";
      outer.style.width = "100px";
      outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

      document.body.appendChild(outer);

      var widthNoScroll = outer.offsetWidth;
      // force scrollbars
      outer.style.overflow = "scroll";

      // add innerdiv
      var inner = document.createElement("div");
      inner.style.width = "100%";
      outer.appendChild(inner);

      var widthWithScroll = inner.offsetWidth;

      // remove divs
      outer.parentNode.removeChild(outer);

      res = widthNoScroll - widthWithScroll;
    end;
    {$ENDIF}
    ScrollBW := res;
  end;

  Result := res;
end;

function GetScrollBarHeight: integer;
var
  res: integer;
begin
  res := ScrollBH;

  if res = 0 then
  begin
    {$IFDEF PAS2JS}
    asm
      var outer = document.createElement("div");
      outer.style.visibility = "hidden";
      outer.style.height = "100px";
      outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

      document.body.appendChild(outer);

      var HeightNoScroll = outer.offsetHeight;
      // force scrollbars
      outer.style.overflow = "scroll";

      // add innerdiv
      var inner = document.createElement("div");
      inner.style.height = "100%";
      outer.appendChild(inner);

      var HeightWithScroll = inner.offsetHeight;

      // remove divs
      outer.parentNode.removeChild(outer);

      res = HeightNoScroll - HeightWithScroll;

      res = 0;
    end;
    {$ENDIF}
    ScrollBH := res;
  end;

  Result := res;
end;


function FindGlobalComponent(const Name: string): TComponent;
begin
  Result := nil;
end;

function FindUniqueName(const Name: string): string;
begin
  Result := Name + IntToStr(ControlManager.GetInstanceNumber);
end;

{ TMargins }

procedure TMargins.SetLeft(const Value: integer);
begin
  if (Value <> FLeft) then
  begin
    FLeft := Value;
    DoChange;
  end;
end;

procedure TMargins.SetTop(const Value: integer);
begin
  if (Value <> FTop) then
  begin
    FTop := Value;
    DoChange;
  end;
end;

procedure TMargins.SetRight(const Value: integer);
begin
  if (Value <> FRight) then
  begin
    FRight := Value;
    DoChange;
  end;
end;

procedure TMargins.SetBottom(const Value: integer);
begin
  if (Value <> FBottom) then
  begin
    FBottom := Value;
    DoChange;
  end;
end;

procedure TMargins.DoChange; 
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

{ TControl }

function TControl.MakeScreenshot: TBitmap;
begin
  Result := nil;
end;

procedure TControl.MouseDown(Button: TMouseButton; Shift: TShiftState; X,
  Y: Integer);
begin
  if not Enabled then
    Exit;

  if Assigned(OnMouseDown) then
   OnMouseDown(Self, Button, Shift, X, Y);
end;

procedure TControl.MouseMove(Shift: TShiftState; X, Y: Integer);
begin
  if not Enabled then
    Exit;

  if Assigned(OnMouseMove) then
   OnMouseMove(Self, Shift, X, Y);
end;

procedure TControl.MouseUp(Button: TMouseButton; Shift: TShiftState; X,
  Y: Integer);
begin
  if not Enabled then
    Exit;

  if Assigned(OnMouseUp) then
   OnMouseUp(Self, Button, Shift, X, Y);
end;

procedure TControl.MouseWheel(Shift: TShiftState; WheelDelta: Integer;
  var Handled: Boolean);
var
  IsNeg: Boolean;
begin
  if Assigned(OnMouseWheel) then
    OnMouseWheel(Self, Shift, WheelDelta, Point(0, 0), Handled);

  Inc(FWheelAccumulator, WheelDelta);
  while Abs(FWheelAccumulator) >= WHEEL_DELTA do
  begin
    IsNeg := FWheelAccumulator < 0;
    FWheelAccumulator := Abs(FWheelAccumulator) - WHEEL_DELTA;
    if IsNeg then
    begin
      if FWheelAccumulator <> 0 then FWheelAccumulator := -FWheelAccumulator;
      DoMouseWheelDown(Shift, FWheelMousePos);
    end
    else
      DoMouseWheelUp(Shift, FWheelMousePos);
  end;
end;

procedure TControl.MoveElements(FromElement, ToElement: TJSElement);
begin
  //
  {$IFDEF PAS2JS}
  asm
    if (FromElement && ToElement) {
      while (FromElement.childNodes.length) { ToElement.appendChild(FromElement.firstChild); }
    }
  end;
  {$ENDIF}
end;

procedure TControl.MoveElementsAndFree(FromElement, ToElement: TJSElement);
begin
  //
  {$IFDEF PAS2JS}
  asm
    if (FromElement && ToElement){
      while (FromElement.childNodes.length) { ToElement.appendChild(FromElement.firstChild); }
      FromElement.remove();
    }
  end;
  {$ENDIF}
end;

procedure TControl.MoveFirst(AControl: TControl);
var
  i: integer;
  fnd: boolean;
begin
  fnd  := false;
  for i := ControlCount - 1 downto 0 do
  begin
    if FControls[i] = AControl then
      fnd := true;

    if fnd then
    begin
      if (i > 0) then
        FControls[i] := FControls[i - 1]
      else
        FControls[i] := AControl;
    end;
  end;
end;

procedure TControl.MoveLast(AControl: TControl);
var
  i: integer;
  fnd: boolean;
begin
  fnd  := false;
  for i := 0 to ControlCount - 1 do
  begin
    if FControls[i] = AControl then
      fnd := true;

    if fnd then
    begin
      if (i < ControlCount - 1) then
        FControls[i] := FControls[i + 1]
      else
        FControls[i] := AControl;
    end;
  end;
end;

procedure TControl.DoMouseLeave;
begin
  if Assigned(OnMouseLeave) then
    OnMouseLeave(Self);
end;

function TControl.DoMouseWheelDown(Shift: TShiftState;
  MousePos: TPoint): Boolean;
begin
  Result := False;
  if Assigned(FOnMouseWheelDown) then
    FOnMouseWheelDown(Self, Shift, MousePos, Result);
end;

function TControl.DoMouseWheelUp(Shift: TShiftState; MousePos: TPoint): Boolean;
begin
  Result := False;
  if Assigned(FOnMouseWheelUp) then
    FOnMouseWheelUp(Self, Shift, MousePos, Result);
end;

procedure TControl.DoMouseEnter;
begin
  if Assigned(OnMouseEnter) then
    OnMouseEnter(Self);
end;

procedure TControl.AlignControls(AControl: TControl; var ARect: TRect);
var
  j: Integer;
//  ORect: TRect;

  procedure DoPosition(Control: TControl; AAlign: TAlign);
  var
    dl, dt, dr, db: integer;
  begin
    if not Control.AnchoringInitialized then
      InitAnchoring;

    Control.FUpdateTopLeft := true;

    if Control.AlignWithMargins then
    begin
      dl := Control.Margins.Left;
      dt := Control.Margins.Top;
      db := Control.Margins.Bottom;
      dr := Control.Margins.Right;
    end
    else
    begin
      dl := 0;
      dt := 0;
      db := 0;
      dr := 0;
    end;

    case AAlign of
      alTop:
      begin
        Control.SetBoundsInt(ARect.Left + dl, ARect.Top + dt, ARect.Right - ARect.Left - dl - dr, Control.Height);
        ARect.Top := ARect.Top + Control.Height + dt + db;
      end;
      alBottom:
      begin
        Control.SetBoundsInt(ARect.Left + dl, ARect.Bottom - Control.Height - db - 1, ARect.Right - ARect.Left - dl - dr - 1, Control.Height);
        ARect.Bottom := ARect.Bottom - Control.Height - dt - db;
      end;
      alLeft:
      begin
        Control.SetBoundsInt(ARect.Left + dl, ARect.Top + dt, Control.Width, ARect.Bottom - ARect.Top - dt - db);
        ARect.Left := ARect.Left + Control.Width + dl + dr;
      end;
      alRight:
      begin
        Control.SetBoundsInt(ARect.Right - Control.Width - dr, ARect.Top + dt, Control.Width, ARect.Bottom - ARect.Top - db - dt);
        ARect.Right := ARect.Right - Control.Width - dr - dl;
      end;
      alClient:
      begin
        Control.SetBoundsInt(ARect.Left + dl, ARect.Top + dt, ARect.Right - ARect.Left - dl - dr, ARect.Bottom - ARect.Top - db - dt);
        // just in case there are multiple controls with client alignment
//        ARect.Left := 0;
//        ARect.Top := 0;
//        ARect.Right := 0;
//        ARect.Bottom := 0;
      end;
    end;

    Control.FUpdateTopLeft := false;
  end;

  procedure DoAlign(AAlign: TAlign);
  var
    i,j,ins: Integer;
    il: TList;
  begin
    il := TList.Create;

    for i := 0 to ControlCount - 1 do
    begin
      if (Controls[i].Align = AAlign) and ((Controls[i].Visible) or (csDesigning in ComponentState)) then
      begin
        ins := il.Count;

        for j := il.Count - 1 downto 0 do
        begin
          case AAlign of
          alTop:
            if Controls[i].FOrigTop < TControl(il.Items[j]).FOrigTop then
              ins := j;
          alLeft:
            if Controls[i].FOrigLeft < TControl(il.Items[j]).FOrigLeft then
              ins := j;
          alRight:
            if Controls[i].FOrigLeft + Controls[i].Width > TControl(il.Items[j]).FOrigLeft + TControl(il.Items[j]).Width then
              ins := j;
          alBottom:
            if Controls[i].FOrigTop + Controls[i].Height > TControl(il.Items[j]).FOrigTop + TControl(il.Items[j]).Height then
              ins := j;
          end;
        end;
        il.Insert(ins, Controls[i]);
      end;
    end;

    for i := 0 to il.Count - 1 do
    begin
      DoPosition(TControl(il.Items[i]), AAlign);
    end;

    il.Free;
  end;

begin
  //ORect := ARect;
  DoAlign(alTop);
  DoAlign(alBottom);
  DoAlign(alLeft);
  DoAlign(alRight);
  DoAlign(alClient);
  DoAlign(alCustom);

  for j := 0 to ControlCount - 1 do
  begin
    Controls[j].AlignControl(Controls[j]);
  end;
end;

procedure TControl.ApplyName;
var
  s, prefix: String;
  frm: TCustomForm;
begin
  s := ClassName;
  Delete(s, 1, 1);

  prefix := '';
  frm := GetParentForm(Self);
  if Assigned(frm) then
    prefix := frm.ClassName;

  SetID(prefix+'_'+FindUniqueName(s));
end;

constructor TControl.Create(ID: string);
begin
  FGotFocus := false;
  FTabStop := true;
  FAllowTouch := true;

  CreateWithID(ID);
end;

procedure TControl.HookElement;
var
  el: TJSElement;
  i: integer;
begin
  el := document.getElementByID(FID);
  Container := el;
  BindElement;
  BindEvents;

  for i := 0 to ControlCount - 1 do
    Controls[i].HookElement;
end;

function TControl.CanAcceptChild(AValue: TControl): TControl;
begin
   Result := Self;
end;

function TControl.CanFocus: Boolean;
begin
  Result := True;
end;

function TControl.CanShowFocus: boolean;
begin
  Result := FShowFocus;
end;

procedure TControl.Capture;
begin
  FCaptureDown := True;
  window.setTimeout(@StartCapture, 100);
end;

procedure TControl.StartCapture;
var
  eh: TJSHTMLElement;
begin
  if not FCaptureDown then
    Exit;

  if Captured then
    ReleaseCapture;

  FMouseInsideLayer := true;

  FLayer := document.createElement('SPAN');
  document.body.appendChild(FLayer);

  eh := TJSHTMLElement(FLayer);
  eh.addEventListener('mouseenter', FLayerMouseEnterPtr);
  eh.addEventListener('mouseleave', FLayerMouseLeavePtr);
  eh.addEventListener('mousedown', FMouseDownPtr);
  eh.addEventListener('mouseup', FMouseUpPtr);
  eh.addEventListener('mousemove', FMouseMovePtr);
  eh.addEventListener('touchstart', FTouchStartPtr);
  eh.addEventListener('touchmove', FTouchMovePtr);
  eh.addEventListener('touchend', FTouchEndPtr);
  eh.addEventListener('touchcancel', FTouchCancelPtr);

  eh.style.setProperty('top', '0');
  eh.style.setProperty('left', '0');
  eh.style.setProperty('right', '0');
  eh.style.setProperty('bottom', '0');

  eh.style.setProperty('webkit-user-select', 'none');
  eh.style.setProperty('moz-user-select', 'none');
  eh.style.setProperty('khtml-user-select', 'none');
  eh.style.setProperty('ms-user-select', 'none');
  eh.style.setProperty('user-select', 'none');
  eh.style.setProperty('position', 'absolute');

  eh.style.setProperty(CSSZIndex, '999999');

  FCaptured := True;
  UpdateElement;
end;

function TControl.Captured: Boolean;
begin
  Result := FCaptured and Assigned(FLayer);
end;

procedure TControl.ClearChildElements;
var
  el: TJSElement;
begin
  if not Assigned(ElementHandle) then
    Exit;

  while ElementHandle.childElementCount > 0 do
  begin
    el := TJSElement(ElementHandle.firstChild);
    ElementHandle.removeChild(el);
  end;
end;

procedure TControl.ClearControls;
begin
  SetLength(FControls, 0);
end;

procedure TControl.Click;
begin
  if Assigned(OnClick) and IsEnabled then
    OnClick(Self);
end;

function TControl.ClientToScreen(const Point: TPoint): TPoint;
var
  Origin: TPoint;
begin
  Origin := ClientOrigin;
  Result.X := Point.X + Origin.X;
  Result.Y := Point.Y + Origin.Y;
end;

procedure TControl.ColorChanging;
begin

end;

function TControl.ContainerElement: TJSElement;
begin
  Result := document.body;
end;

function TControl.RequiredBaseURL: string;
begin
  Result := '';
end;

procedure TControl.RequiredScriptLoaded(Event: TJSEvent);
begin
  (Event.Target as TJSHTMLScriptElement).Title := 'tmswebloaded';
  inc(FControlScriptCountLoaded);
end;

constructor TControl.Create(AOwner: TComponent);
var
  lName: string;
  frm: TCustomForm;
  lDesign: boolean;

begin
  FGotFocus := false;
  FEventsBound := false;
  FTabStop := true;
  FAllowTouch := true;
  FNoUserSelect := true;
  ClearMethodPointers;

  inherited Create(AOwner);

  EventStopPropagation := [eeClick, eeDblClick, eeMouseUp, eeMouseMove, eeMouseDown, eeKeypress, eeKeyDown, eeKeyUp];

  FScriptLoaded := false;
  FElementFont := efProperty;
  FElementPosition := epAbsolute;
  FParentElement := nil;
  FTextDirection := tdDefault;

  lDesign := false;

  frm := GetParentForm(Self);
  if Assigned(frm) then
  begin
    lDesign := (csDesigning in frm.ComponentState);
  end;

  lName := GetNewName;

  // for VSC, when creating control, initialize without ID
  if lDesign then
    CreateWithID('')
  else
    CreateWithID(lName);

  if Assigned(AOwner) and not (csDesigning in AOwner.ComponentState) then
    Name := lName;
end;


procedure TControl.CreateControl;
begin
  if not Assigned(FElement) then
  begin
    FElement := CreateElement;

    if Assigned(FElement) then
    begin
      FControlCreated := True;
      Container := FElement;
      if not (ElementID = 'body') then
        Container['id'] := ElementID;
      Container['zindex'] := '0';
      if FElementClassName <> '' then
        Container['class'] := FElementClassName;
      CreateChildElements(FElement);

      BindEvents;
      UpdateElement;
    end;
  end;
end;

function TControl.CreateElement: TJSElement;
begin
  Result := nil;
end;

procedure TControl.CreateChildElements(AContainer: TJSElement);
begin
end;

procedure TControl.CreateInitialize;
begin
  FRequiredScripts := TStringList.Create;
  FOrigRect := Rect(-1,-1,-1,-1);
  FClipChildren := True;
end;

procedure TControl.CreateWithID(AID: string);
var
  el: TJSElement;
begin
  ClearMethodPointers;

  FUpdateCount := 0;

  FControlCreated := False;
  FLinkTouchEvents := True;
  FIsResizing := false;

  if (Assigned(Owner) and (csLoading in Owner.ComponentState)) or not Assigned(Owner) then
  begin
    if not (Assigned(Owner) and (csDesigning in Owner.ComponentState)) then
      Loading;
  end;

  FElementPosition := epAbsolute;
  FWidthStyle := ssAbsolute;
  FHeightStyle := ssAbsolute;

  el := document.getElementByID(AID);

  FNew := not Assigned(el);

  FFont := TFont.Create;
  FFont.OnChange := HandleFontChanged;

  // the HTML element is not found
  if FNew then
  begin
    Container := nil;
  end
  else
  begin
    Container := el;
    FControlCreated := true;
    FElementPosition := epRelative;
    CreateChildElements(el);
    BindElement;
    BindEvents;
  end;

  FID := AID;
  FEnabled := True;
  FVisible := True;
  FLeft := 0;
  FTop := 0;
  FAlign := alNone;
  FAlignWithMargins := false;
  FIsAligning := false;
  FParentFont := true;
  FParentBiDiMode := true;
  FParentColor := false;
  FAnchors := [akLeft, akTop];
  FShowFocus := false;
  FBorderWidth := 0;
  FEnablePropagation := False;
  FToolTip := nil;

  FColor := clWindow;

  FMargins := TMargins.Create;
  FMargins.OnChange := DoMarginsChanged;

  FParent := nil;
  FPrevParent := nil;

  // Issue with Visual Studio Code design-time assigning ElementID for conntrols with childs
  if not (csAcceptsControls in ControlStyle) then
    ClearControls;

  CreateInitialize;
end;

procedure TControl.BindElement;
begin
end;

procedure TControl.AddControlLink(const linkid, link: string);
begin
  {$IFDEF PAS2JS}
  asm
    function writeLinkOnce(linkName, linkText) {
        var linkElement = document.getElementById(linkName);
        if (linkElement)
          return;
        linkElement = document.createElement('link');
        linkElement.id = linkName;
        linkElement.setAttribute('rel', 'stylesheet');
        linkElement.setAttribute('type', 'text/css');
        linkElement.setAttribute('href', linkText);
        document.getElementsByTagName('head')[0].appendChild(linkElement);
    }
    writeLinkOnce(linkid,link);
  end;
  {$ENDIF}
end;


{$HINTS OFF}
procedure TControl.AddControlScriptLink(const link: string);
var
  id: string;
  script: TJSHTMLScriptElement;

begin
  id := ClassName;

  if not Assigned(document.getElementById(id)) then
  begin
    script := TJSHTMLScriptElement(document.createElement('script'));
    script.id := id;
    script.src := link;
    script.type_ := 'text/javascript';
    document.head.appendChild(script);
  end;
end;

procedure TControl.AddControlScriptSource(const source: string);
var
  id: string;
  script: TJSHTMLScriptElement;

begin
  id := ClassName+'SRC';

  if not Assigned(document.getElementById(id)) then
  begin
    script := TJSHTMLScriptElement(document.createElement('script'));
    script.id := id;
    script.innerHTML := source;
    script.type_ := 'text/javascript';
    document.head.appendChild(script);
  end;
end;


procedure TControl.AddControlStyle(const css: string);
var
  cssname: string;
begin
  cssname := ClassName;
  {$IFDEF PAS2JS}
  asm
    function writeStylesOnce(styleName, cssText) {
        var styleElement = document.getElementById(styleName);
        if (styleElement) {
             styleElement.innerHTML = cssText;
          return;
          }
        styleElement = document.createElement('style');
        styleElement.type = 'text/css';
        styleElement.id = styleName;
        styleElement.innerHTML = cssText;
        document.getElementsByTagName('head')[0].appendChild(styleElement);
    }
    writeStylesOnce(cssname,css);
  end;
  {$ENDIF}
end;

class procedure TControl.AddInstanceStyle(const id: string; const css: string);
begin
  {$IFDEF PAS2JS}
  asm
    function writeStylesOnce(styleName, cssText) {
        var styleElement = document.getElementById(styleName);
        if (styleElement) {
          styleElement.innerHTML = cssText;
          return;
          }
        styleElement = document.createElement('style');
        styleElement.type = 'text/css';
        styleElement.id = styleName;
        styleElement.innerHTML = cssText;
        document.getElementsByTagName('head')[0].appendChild(styleElement);
    }
    writeStylesOnce(id,css);
  end;
  {$ENDIF}
end;

procedure TControl.AddInstanceStyle(const css: string);
var
  cssname: string;
begin
  cssname := Name + '_style';
  AddInstanceStyle(cssname, css);
end;

procedure TControl.AddRequiredScript(const link: string);
var
  id: string;
  script: TJSHTMLScriptElement;

begin
  script := TJSHTMLScriptElement(document.createElement('script'));
  script.src := link;
  script.type_ := 'text/javascript';
  script.title := 'tmswebloading';

  if not Assigned(FScriptLoadedPtr) then
    FScriptLoadedPtr := @RequiredScriptLoaded;

  script.addEventListener('load', FScriptLoadedPtr);
  document.head.appendChild(script);
end;

function TControl.AddRequiredScripts: boolean;
var
  i,j: integer;
  el: TJSElement;
  scr: TJSHTMLScriptElement;
  found, loading: boolean;
begin
  FControlScriptCount := 0;

  for j := 0 to RequiredScripts.Count - 1 do
  begin
    found := false;
    loading := false;

    for i := 0 to document.head.children.length - 1 do
    begin
      el := TJSElement(document.head.children[i]);
      if (el.tagName = 'SCRIPT') then
      begin
        scr := TJSHTMLScriptElement(el);
        if (pos(RequiredScripts[j], scr.src) > 0) then
        begin
          found := true;

          if scr.title = 'tmswebloading' then
          begin
            loading := true;
            if not Assigned(FScriptLoadedPtr) then
              FScriptLoadedPtr := @RequiredScriptLoaded;
            scr.addEventListener('load', FScriptLoadedPtr);
          end;

          break;
        end;
      end;
    end;

    if not found then
    begin
      inc(FControlScriptCount);
      AddRequiredScript( RequiredBaseURL + RequiredScripts[j]);
    end;

    if found and loading then
      inc(FControlScriptCount);
  end;

  Result := (FControlScriptCount > 0);
end;

{$HINTS ON}

procedure TControl.AlignControl(AControl: TControl);
var
  r: TRect;
  cr: TJSDOMRect;
  ovf, ovfx, ovfy: string;
  eh: TJSHTMLElement;
  el: TJSElement;
  frm: TCustomForm;
  ctop: integer;
  vscrl: integer;

begin
  if FIsAligning then
    Exit;

  if IsUpdating then
    Exit;

  if csLoading in ComponentState then
    Exit;

//  if not Visible then
//    Exit;

  if Assigned(Parent) and not Parent.Visible then
    Exit;

  frm := GetParentForm(Self);

  if Assigned(frm) and frm.IsUpdating then
    Exit;

  ctop := 0;

  if Assigned(frm) and (frm.FormContainer <> '') and (AControl is TCustomForm) then
  begin
    el := document.getElementById(frm.FormContainer);
    if Assigned(el) then
    begin
      cr := el.getBoundingClientRect;
      ctop := Round(cr.Top + 1);
    end;
  end;

  FIsAligning := true;

  if not Assigned(AControl) then
    Exit;

  eh := AControl.ElementHandle;

  if Assigned(eh) then
  begin
    ovf := AControl.GetElementStyle.getPropertyValue('overflow');
    ovfx := AControl.GetElementStyle.getPropertyValue('overflow-x');
    ovfy := AControl.GetElementStyle.getPropertyValue('overflow-y');

    if ClipChildren and (LowerCase(eh.tagName) <> cBodyTag) then
      AControl.GetElementStyle.setProperty('overflow', 'hidden')
    else
      AControl.GetElementStyle.setProperty('overflow', '');
  end;

  r := GetClientRect;
  // when it concerns a control linked to an element, need to get the element bounding rect here
  if IsLinked then
  begin
    cr := ElementHandle.getBoundingClientRect;
    // get the real dimensions of the control, it will be absolutely positioned taking the elements
    // real top,left in account in SetBoundsInt()
    r := Rect(0, 0, Round(cr.Right - cr.Left), Round(cr.Bottom - cr.Top));
  end;

  // limit size except when it is absolute positioned & has fixed height
  if (r.Bottom + ctop > WinHeight) and (HeightStyle <> ssAbsolute) and not (ElementPosition = epAbsolute) and not IsLinked and not Assigned(VSIDE) then
  begin
    if (Assigned(Parent) and (Parent is TCustomForm)) or (Self is TCustomForm) then
    begin
      r.Bottom := WinHeight - ctop;
    end;
  end;

  if Assigned(eh) and (eh.tagName = 'BODY') then
  begin
    vscrl := 0;

    if (document.body.scrollHeight > document.body.clientHeight) and not (csDesigning in ComponentState) then
      vscrl := 16;

    if frm.FormContainer <> '' then
    begin
      r.Bottom := Round(Min(r.Bottom , WinHeight));
      r.Right := Round(Min(r.Right - vscrl, WinWidth));
    end
    else
    begin
      r.Bottom := frm.Height;
      r.Right := frm.Width;
    end;
  end;

  // limit size to browser window size anyway
  if (AControl is TCustomForm) and not Assigned(VSIDE) then
  begin
    if r.Bottom > WinHeight then
      r.Bottom := WinHeight;

    if r.Right > WinWidth then
      r.Right := WinWidth;
  end;

  if AControl.IsStructuralElement then
  begin
    r := Parent.GetClientRect;
  end;

  AlignControls(AControl, r);

  if Assigned(eh) then
  begin
    AControl.GetElementStyle.setProperty('overflow', ovf);
    AControl.GetElementStyle.setProperty('overflow-x', ovfx);
    AControl.GetElementStyle.setProperty('overflow-y', ovfy);
  end;

  FIsAligning := false;
end;

procedure TControl.DblClick;
begin
  if Assigned(OnDblClick) then
    OnDblClick(Self);
end;

destructor TControl.Destroy;
begin
  UnbindEvents;
  DestroyControls;

  if Assigned(Container) and Assigned(Parent) and Assigned(Parent.GetChildContainer) then
  begin
    if Parent.GetChildContainer = Container.parentNode then
    begin
      Parent.GetChildContainer.removeChild(Container);
    end;

    Container := nil;
    Parent := nil;
    FControlCreated := False;
  end;

  FRequiredScripts.Free;
  FMargins.Free;
  FFont.Free;

  if Assigned(FToolTip) then
  begin
    {$IFDEF PAS2JS}
    asm
      FToolTip.dispose();
    end;
    {$ENDIF}
    FToolTip := nil;
  end;

  inherited;
end;

procedure TControl.DestroyControls;
var
  i: integer;
  ctrl: TControl;
begin
  for i := ControlCount - 1 downto 0 do
  begin
    ctrl := Controls[i];
    ctrl.Free;
  end;
end;

procedure TControl.DisableDrag;
begin
  if Assigned(Container) and (Container['draggable'] = 'true') then
    Container['draggable'] := 'false';
end;

procedure TControl.DisableTab;
var
  i: integer;
begin
  if not (TabStop and CanFocus) then
  begin
    if Assigned(Container) then
      Container.setAttribute('tabindex','-1');
  end;

  for i := 0 to ControlCount - 1 do
  begin
    Controls[i].DisableTab;
  end;
end;

procedure TControl.DisposeOf;
var
  t: TControl;
begin
  t := Self;
  t.Free;
end;

procedure TControl.Assign(Source: TPersistent);
begin

end;

function TControl.GetElement: TJSElement;
begin
  Result := document.getElementByID(GetID);
end;

function TControl.GetElementBindHandle: TJSEventTarget;
begin
  Result := TJSEventTarget(GetElementHandle);
end;

function TControl.GetElementEvent: TJSEvent;
begin
  Result := FElementEvent;
end;

procedure TControl.SetEnabled(Value: Boolean);
begin
  if (FEnabled <> Value) then
  begin
    FEnabled := Value;
    UpdateElementData;
  end;
end;

procedure TControl.SetVisible(AValue: Boolean);
begin
  if (FVisible <> AValue) then
  begin
    VisibleChanging;
    FVisible := AValue;
    UpdateElement;
    DoRealign;
    AlignControl(Self);
    VisibleChanged;
  end;
end;

procedure TControl.SetWidth(AValue: Integer);
begin
  if (FWidth <> AValue) then
  begin
    FWidth := AValue;

    ResetAnchoring;
    DoBoundsChange;

    // still do element height updating at runtime
    if IsLinked and not IsUpdating and Assigned(ElementHandle) then
    begin
      if AValue >= 0 then
        GetElementStyle.setProperty('width',IntTostr(AValue)+'px')
      else
        GetElementStyle.removeProperty('width');
    end;
  end;
end;

procedure TControl.SetWidthInt(AValue: Integer);
begin
  FWidth := AValue;
end;

procedure TControl.SetWidthPercent(const Value: TPercentSize);
begin
  if (FWidthPercent <> Value) then
  begin
    FWidthPercent := Value;
    UpdateElementSize;
    ResetAnchoring;
    DoBoundsChange;
  end;
end;

procedure TControl.SetWidthStyle(const Value: TSizeStyle);
begin
  if (FWidthStyle <> Value) then
  begin
    FWidthStyle := Value;

    if (FWidthStyle = ssAuto) and Assigned(ElementHandle) then
      GetElementStyle.removeProperty('width');

    UpdateElementSize;
    ResetAnchoring;
    DoBoundsChange;
  end;
end;

procedure TControl.Show;
begin
  Visible := true;
end;

procedure TControl.StopPropagation;
begin
  if Assigned(ElementEvent) and not EnablePropagation then
    ElementEvent.stopPropagation;
end;

procedure TControl.TouchCancel(X, Y: Integer);
begin
  if Assigned(OnTouchCancel) then
    OnTouchCancel(Self, X, Y);
end;

procedure TControl.TouchEnd(X, Y: Integer);
begin
  if Assigned(OnTouchEnd) then
    OnTouchEnd(Self, X, Y);
end;

procedure TControl.TouchMove(X, Y: Integer);
begin
  if Assigned(OnTouchMove) then
    OnTouchMove(Self, X, Y);
end;

procedure TControl.TouchStart(X, Y: Integer);
begin
  if Assigned(OnTouchStart) then
    OnTouchStart(Self, X, Y);
end;

procedure TControl.SetHeight(AValue: Integer);
var
  dr: TJSDOMRect;
begin
  if FHeight <> AValue then
  begin
    if (Align in [alLeft, alRight, alClient]) and Assigned(ElementHandle) then
    begin
      dr := ElementHandle.getBoundingClientRect;
      if dr.top + AValue >= WinHeight then
      begin
        AValue := Round(WinHeight - dr.top);
      end;
    end;

    FHeight := AValue;

    ResetAnchoring;
    DoBoundsChange;

    // still do element height updating at runtime
    if IsLinked and not IsUpdating and Assigned(ElementHandle) then
    begin
      if AValue >= 0 then
        GetElementStyle.setProperty('height',IntTostr(AValue)+'px')
      else
        GetElementStyle.removeProperty('height');
    end;
  end;
end;

procedure TControl.SetHeightInt(AValue: Integer);
begin
  FHeight := AValue;
end;

procedure TControl.SetHeightPercent(const Value: TPercentSize);
begin
  if (FHeightPercent <> Value) then
  begin
    FHeightPercent := Value;
    UpdateElementSize;
    ResetAnchoring;
    DoBoundsChange;
  end;
end;

procedure TControl.SetHeightStyle(const Value: TSizeStyle);
begin
  if (FHeightStyle <> Value) then
  begin
    FHeightStyle := Value;

    if (FHeightStyle = ssAuto) and Assigned(ElementHandle) then
      GetElementStyle.removeProperty('height');

    UpdateElementSize;
    ResetAnchoring;
    DoBoundsChange;
  end;
end;

procedure TControl.SetLeft(AValue: Integer);
begin
  if FLeft <> AValue then
  begin
    FLeft := AValue;

    ResetAnchoring;

    if not FUpdateTopLeft then
      FOrigLeft := AValue;

    UpdateElementSize;
    RecreateCanvas;
    InternalResize;

    if not (csLoading in ComponentState) and (Align <> alNone) and Assigned(Parent) then
    begin
      DoRealign;
    end;
  end;
end;

procedure TControl.SetMargins(const Value: TMargins);
begin
  FMargins.Assign(Value);
end;

procedure TControl.SetTop(AValue: Integer);
begin
  if FTop <> AValue then
  begin
    FTop := AValue;

    ResetAnchoring;

    if not FUpdateTopLeft then
      FOrigTop := AValue;

    UpdateElementSize;
    RecreateCanvas;
    InternalResize;

    if not (csLoading in ComponentState) and (Align <> alNone) and Assigned(Parent) then
    begin
      DoRealign;
    end;
  end;
end;

procedure TControl.SetScriptLoaded(const Value: boolean);
begin
  FScriptLoaded := Value;
end;

procedure TControl.SetShowFocus(const Value: boolean);
begin
  if (FShowFocus <> Value) then
  begin
    FShowFocus := Value;
    UpdateElement;
  end;
end;

procedure TControl.SetShowHint(AValue: Boolean);
begin
  if FShowHint <> AValue then
  begin
    FShowHint := AValue;
    UpdateElement;
  end;
end;

procedure TControl.SetHint(AValue: string);
begin
  if FHint <> AValue then
  begin
    FHint := AValue;
    UpdateElement;
  end;
end;

{$HINTS OFF}
procedure TControl.SetID(const Value: string);
var
  eh,el,tmp: TJSElement;
  frm: TCustomForm;
  p: TComponent;
  l,t,w,h: integer;

begin
  // it was a new created control and now going to be a HTML element wrapper

  if FNew and (FID <> Value) then
  begin
    // switch to template element - so find it
    eh := document.getElementById(Value);
    if Assigned(eh) then
    begin
      l := FLeft;
      t := FTop;
      w := FWidth;
      h := FHeight;
      tmp := nil;

      // unbind event handlers from created control
      UnbindEvents;

      if Assigned(FContainer) then
      begin
        // remember previous HTML parent element
        FParentElement := TJSHTMLElement(FContainer.parentElement);

        // move child elements from current control to HTML element
        tmp := document.createElement('SPAN');
        MoveElements(FContainer, tmp);
      end;

      p := Parent;

      // remove created control
      Parent := nil;

      // create wrapper for HTML element
      CreateWithID(Value);

      // add it back as child
      SetParentComponent(p);

      FID := Value;

      FWidth := w;
      FHeight := h;

      // perform update to set wrapper properties to HTML element
      UpdateElement;

      // keep Left,Top at design-time for Delphi compatibility
      if (csDesigning in ComponentState) then
      begin
        FLeft := l;
        FTop := t;
      end;

      if Assigned(tmp) then
        MoveElementsAndFree(tmp, ElementHandle);

      Exit;
    end;
  end;

  // it is a wrapped control and now going to be a new created control
  if not FNew and (FID <> Value) then
  begin
    w := FWidth;
    h := FHeight;
    // unbind event handlers from wrapped HTML element
    eh := document.getElementById(FID);
    if Assigned(eh) then
    begin
      // move child elements to temp SPAN
      tmp := document.createElement('SPAN');
      MoveElements(eh, tmp);
      UnbindEvents;
      //DestroyControls;
    end;

    if (Value <> '') then
    begin
      eh := document.getElementById(Value);
      // wrap another HTML element
      if Assigned(eh) then
      begin
        CreateWithID(Value);
        FID := Value;
        FWidth := w;
        FHeight := h;
        UpdateElement;

        MoveElementsAndFree(tmp, FContainer);

        Exit;
      end;
    end;

    // create new control
    FNew := true;
    FElement := nil;

    CreateControl;

    if Value = '' then
    begin
      CreateInitialize;
      FWidth := w;
      FHeight := h;
    end;

    if Assigned(FParentElement) then
    begin
      FParentElement.appendChild(Container);
    end
    else
      document.body.appendChild(Container);

    frm := GetParentForm(Self); //Application.ActiveForm;
    if Assigned(frm) then
    begin
      Parent := frm;
      ElementPosition := epAbsolute;
      Left := 0;
      Top := 0;
    end;

    // move child elements from temp SPAN to new control
    if Assigned(tmp) then
      MoveElementsAndFree(tmp, FContainer);

    // perform update to set instance properties to control
    UpdateElement;
    UpdateElementSize;
  end;

  FID := Value;
  if Assigned(FContainer) then
    FContainer['id'] := Value;
end;
{$HINTS ON}

procedure TControl.SetTabOrder(AValue: Integer);
begin
  if FTabOrder <> AValue then
  begin
    FTabOrder := AValue;
    UpdateElement;
  end;
end;

procedure TControl.SetTabStop(AValue: Boolean);
begin
  if FTabStop <> AValue then
  begin
    FTabStop := AValue;
    UpdateElement;
  end;
end;

function TControl.SaveState: string;
begin
  Result := '';
end;

function TControl.ScreenToClient(const Point: TPoint): TPoint;
var
  Origin: TPoint;
begin
  Origin := ClientOrigin;
  Result.X := Point.X - Origin.X;
  Result.Y := Point.Y - Origin.Y;
end;

procedure TControl.SendToBack;
begin
  if Assigned(ElementHandle) and Assigned(ElementHandle.parentElement) then
  begin
    ElementHandle.parentElement.insertBefore(ElementHandle, ElementHandle.parentElement.firstElementChild);
    //if not ((ElementPosition <> epAbsolute) or IsLinked) then
    //  ElementHandle.style.setProperty(CSSZIndex, '0');

    if Assigned(Parent) then
      Parent.MoveFirst(Self);
  end
end;


procedure TControl.BeginDrag;
begin
  EnableDrag;
end;

procedure TControl.BeginUpdate;
begin
  inc(FUpdateCount);
end;

procedure TControl.Invalidate;
begin

end;

procedure TControl.EnableDrag;
begin
  if Assigned(Container) then
    Container['draggable'] := 'true';
end;

procedure TControl.EnableTab;
var
  i: integer;
begin
  if Assigned(FContainer) and not IsLinked then
  begin
    if TabStop and CanFocus then
    begin
      FContainer['tabindex'] := IntToStr(TabOrder + 1)
    end
    else
    begin
      FContainer['tabindex'] := '-1';
    end;
  end;

  for i := 0 to ControlCount - 1 do
  begin
    Controls[i].EnableTab;
  end;
end;

procedure TControl.EndUpdate;
begin
  if (FUpdateCount > 0) then
  begin
    dec(FUpdateCount);

    if FUpdateCount = 0 then
      UpdateElement;
  end;
end;

function TControl.EventsBound: boolean;
begin
  Result := FEventsBound;
end;

function TControl.IsUpdating: boolean;
begin
  Result := FUpdateCount > 0;
end;

procedure TControl.SetAlign(const Value: TAlign);
begin
  if FAlign <> Value then
  begin
    FAlign := Value;
    DoRealign;
  end;
end;

procedure TControl.SetAlignWithMargins(const Value: boolean);
begin
  if FAlignWithMargins <> Value then
  begin
    FAlignWithMargins := Value;
    DoRealign;
  end;
end;

procedure TControl.SetBiDiMode(const Value: TBiDiMode);
begin
  if Assigned(ElementHandle) then
  begin
    if (Value = bdLeftToRight) then
      ElementHandle.removeAttribute('dir')
    else
      ElementHandle.setAttribute('dir','rtl');
  end;
end;

procedure TControl.DoMarginsChanged(Sender: TObject);
begin
  DoRealign;
end;

procedure TControl.DoRealign;
begin
  if Assigned(Parent) and not Parent.IsUpdating then
  begin
    Parent.AlignControl(Parent);
  end;
end;

procedure TControl.SetAnchors(const Value: TAnchors);
begin
  if (FAnchors <> Value) then
  begin
    FAnchors := Value;
    if not (csLoading in ComponentState) then
    begin
      UpdateAnchoring;
    end;
  end;
end;

procedure TControl.SetBorderWidth(const Value: integer);
begin
  if (FBorderWidth <> Value) then
  begin
    FBorderWidth := Value;
    UpdateElement;
  end;
end;

procedure TControl.SetBoundsInt(X, Y, AWidth, AHeight: Integer);
begin
  if (X <> Left) or (Y <> Top) or ((AWidth <> Width) and (WidthStyle = ssAbsolute)) or ((AHeight <> Height) and (HeightStyle = ssAbsolute)) or
    (Assigned(Parent) and Parent.isLinked) then
  begin
    FBlockUpdateElement := True;
    FLeft := X;
    FTop := Y;

    FWidth := AWidth;
    FHeight := AHeight;

    FBlockUpdateElement := False;

    UpdateElementSize;

    if Align <> alNone then
      DoRealign
    else
      Realign;

    RecreateCanvas;

    DoBoundsChange;
    Invalidate;
    UpdateChildAnchoring;
  end;
end;

procedure TControl.SetBounds(X, Y, AWidth, AHeight: Integer);
begin
  SetBoundsInt(X, Y, AWidth, AHeight);
  FWidth := AWidth;
  FHeight := AHeight;

  if not FUpdateTopLeft then
  begin
    FOrigTop := Y;
    FOrigLeft := X;
  end;

  ResetAnchoring;

  if not (csLoading in ComponentState) and (Align <> alNone) and Assigned(Parent) then
  begin
    DoRealign;
  end;
end;

procedure TControl.SetBoundsRect(const Value: TRect);
begin
  SetBoundsInt(Value.Left, Value.Top, Value.Right - Value.Left, Value.Bottom - Value.Top);
end;

procedure TControl.SetElementClassName(AValue: string);
begin
  if (FElementClassName <> AValue) then
  begin
    FElementClassName := AValue;
    if Assigned(FContainer) then
      FContainer['class'] := AValue;
    UpdateElement;
  end;
end;

procedure TControl.SetElementColor(AElement: TJSHTMLElement; AColor: TColor);
var
  noCSS: boolean;
begin
  noCSS := (not Application.Themed and not (ElementClassName <> ''));

  if not IsLinked then
    SetHTMLElementColor(AElement, AColor, not noCSS);
end;

procedure TControl.SetElementFont(const Value: TElementFont);
begin
  if (FElementFont <> Value) then
  begin
    FElementFont := Value;
    UpdateElementVisual;
  end;
end;

procedure TControl.SetElementPointer(AElement: TJSHTMLElement;
  ACursor: TCursor);
begin
  if ACursor <> crDefault then
    AElement.style.setProperty('cursor', GetHTMLCursorName(ACursor))
  else
    AElement.style.removeProperty('cursor');
end;

procedure TControl.SetElementPosition(const Value: TElementPosition);
begin
  if (FElementPosition <> Value) then
  begin
    FElementPosition := Value;
    if Assigned(ElementHandle) then
    begin
      UpdateElementSize;
      RecreateCanvas;
      InternalResize;
    end;
  end;
end;


{$HINTS OFF}
procedure TControl.SetFocus;
var
  e: TJSHTMLElement;
begin
  if not CanFocus then
    Exit;

  if Assigned(ElementHandle) then
  begin
    e := ElementHandle;
    if FGotFocus then
    begin
      {$IFDEF PAS2JS}
      asm
        e.focus();
      end;
      {$ENDIF}
    end
    else
    begin
      {$IFDEF PAS2JS}
      asm
        setTimeout(function() {e.focus();}, 1);
      end;
      {$ENDIF}
    end;
  end;
end;
{$HINTS ON}

procedure TControl.SetFont(AValue: TFont);
begin
  FFont.Name := AValue.Name;
  FFont.Size := AValue.Size;
  FFont.Style := AValue.Style;
  FFont.Color := AValue.Color;
end;

procedure TControl.SetChildOrderEx(const Value: integer);
begin
  FChildOrder := Value;
end;

procedure TControl.SetClientHeight(const Value: Integer);
begin
  Height := Value;
end;

procedure TControl.SetClientWidth(const Value: Integer);
begin
  Width := Value;
end;

procedure TControl.SetClipChildren(const Value: Boolean);
begin
  FClipChildren := Value;
  UpdateElement;
end;

procedure TControl.SetColor(AValue: TColor);
begin
  FColor := AValue;
  UpdateElementVisual;
  ColorChanging;
end;

procedure TControl.SetControlCursor(const Value: TCursor);
begin
  FCursor := Value;

  if (ElementClassName = '') and Assigned(ElementHandle) then
  begin
    SetElementPointer(ElementHandle, Value);
  end;
end;

procedure TControl.SetDragMode(const Value: TDragMode);
begin
  FDragMode := Value;
  if Value = dmAutomatic then
    BeginDrag
  else
    DisableDrag;
end;

function TControl.GetID: string;
begin
  Result := FID;
end;

function TControl.GetIsLinked: boolean;
begin
  Result := not FNew;
end;

function TControl.GetElementHandle: TJSHTMLElement;
begin
  Result := nil;
  if Assigned(FContainer) and FControlCreated then
    Result := TJSHTMLElement(FContainer);
end;

function TControl.GetMouseEventButton(Event: TJSMouseEvent): TMouseButton;
begin
  Result := mbLeft;
  case Event.button of
    0: Result := mbLeft;
    1: Result := mbMiddle;
    2: Result := mbRight;
  end;
end;

function TControl.GetMouseEventShiftState(Event: TJSMouseEvent): TShiftState;
begin
  Result := [];
  if Event.shiftKey then
    Result := Result + [ssShift];

  if Event.ctrlKey then
    Result := Result + [ssCtrl];

  if Event.altKey then
    Result := Result + [ssAlt];

  if Event.buttons and 1 = 1 then
     Result := Result + [ssLeft];

  if Event.buttons and 2 = 2 then
     Result := Result + [ssRight];

  if Event.buttons and 4 = 4 then
     Result := Result + [ssMiddle];
end;

function TControl.GetMouseWheelEventShiftState(
  Event: TJSWheelEvent): TShiftState;
begin
  Result := [];
  if Event.shiftKey then
    Result := Result + [ssShift];
  if Event.ctrlKey then
    Result := Result + [ssCtrl];
  if Event.altKey then
    Result := Result + [ssAlt];
end;

function TControl.GetNewName: string;
var
  s: string;
  lPrefix: string;
  frm: TCustomForm;
begin
  s := ClassName;
  Delete(s, 1, 1);

  lPrefix := '';

  frm := GetParentForm(Self);
  if Assigned(frm) then
    lPrefix := frm.ClassName;

  Result := lPrefix+'_'+FindUniqueName(s);
end;

function TControl.GetOuterHeight: integer;
begin
  Result := FHeight - FBorderWidth;
end;

function TControl.GetOuterWidth: integer;
begin
  Result := FWidth - FBorderWidth;
end;

function TControl.GetRole: string;
begin
  Result := FRole;
  if Assigned(ElementHandle) then
    Result := ElementHandle.getAttribute('role');
end;

function TControl.GetElementStyle: TJSCSSStyleDeclaration;
begin
  Result := ElementHandle.style;
end;

function TControl.GetStyle(css: string): string;
var
  res: string;
  el: TJSElement;
begin
  el := ElementHandle;
  res := '';

  if not Assigned(el) then
    Exit;
  {$IFDEF PAS2JS}
  asm
    if (window.getComputedStyle) res = getComputedStyle(el, '')[css];
        if(el.currentStyle) res = el.currentStyle[css];

  end;
  {$ENDIF}
  Result := res;
end;

function TControl.GetKeyboardEventShiftState(Event: TJSKeyboardEvent): TShiftState;
begin
  Result := [];
  if Event.shiftKey then
    Result := Result + [ssShift];
  if Event.ctrlKey then
    Result := Result + [ssCtrl];
  if Event.altKey then
    Result := Result + [ssAlt];
end;

procedure TControl.ClearMethodPointers;
begin
  FWheelPtr := nil;
  FClickPtr := nil;
  FDblClickPtr := nil;
  FMouseDownPtr := nil;
  FMouseUpPtr := nil;
  FMouseMovePtr := nil;
  FMouseLeavePtr := nil;
  FMouseEnterPtr := nil;
  FKeyDownPtr := nil;
  FKeyUpPtr := nil;
  FKeypressPtr := nil;
  FInputPtr := nil;
  FEnterPtr := nil;
  FExitPtr := nil;
  FTouchStartPtr := nil;
  FTouchEndPtr := nil;
  FTouchMovePtr := nil;
  FTouchCancelPtr := nil;
  FLayerMouseLeavePtr := nil;
  FLayerMouseEnterPtr := nil;
  FScriptLoadedPtr := nil;
  FContextMenuPtr := nil;
end;

procedure TControl.GetMethodPointers;
begin
  if FWheelPtr = nil then
  begin
    FWheelPtr := @HandleDoWheel;
    FClickPtr := @HandleDoClick;
    FDblClickPtr := @HandleDoDblClick;
    FMouseDownPtr := @HandleDoMouseDown;
    FMouseUpPtr := @HandleDoMouseUp;
    FMouseMovePtr := @HandleDoMouseMove;
    FMouseLeavePtr := @HandleDoMouseLeave;
    FMouseEnterPtr := @HandleDoMouseEnter;
    FKeyDownPtr := @HandleDoKeyDown;
    FKeyUpPtr := @HandleDoKeyUp;
    FKeypressPtr := @HandleDoKeyPress;
    FInputPtr := @HandleDoInput;
    FEnterPtr := @HandleDoEnter;
    FExitPtr := @HandleDoExit;
    FTouchStartPtr := @HandleDoTouchStart;
    FTouchEndPtr := @HandleDoTouchEnd;
    FTouchMovePtr := @HandleDoTouchMove;
    FTouchCancelPtr := @HandleDoTouchCancel;
    FLayerMouseLeavePtr := @LayerHandleDoMouseLeave;
    FLayerMouseEnterPtr := @LayerHandleDoMouseEnter;
    FScriptLoadedPtr := @RequiredScriptLoaded;
    FDragStartPtr := @HandleDoDragStart;
    FDragOverPtr := @HandleDoDragOver;
    FDragEnterPtr := @HandleDoDragEnter;
    FDragLeavePtr := @HandledoDragLeave;
    FDragEndPtr := @HandleDoDragEnd;
    FDragDropPtr := @HandleDoDragDrop;
    FContextMenuPtr := @HandleDoContextMenu;
  end;
end;

procedure TControl.BindEvents;
var
  eh: TJSEventTarget;
begin
  if Assigned(ElementBindHandle) then
  begin
    GetMethodPointers;
    FEventsBound := true;
    eh := ElementBindHandle;
    eh.addEventListener('wheel', FWheelPtr);
    eh.addEventListener('click', FClickPtr);
    eh.addEventListener('dblclick', FDblClickPtr);
    eh.addEventListener('mousedown', FMouseDownPtr);
    eh.addEventListener('mouseup', FMouseUpPtr);
    eh.addEventListener('mousemove', FMouseMovePtr);
    eh.addEventListener('mouseleave', FMouseLeavePtr);
    eh.addEventListener('mouseenter', FMouseEnterPtr);
    eh.addEventListener('keydown', FKeyDownPtr);
    eh.addEventListener('keyup', FKeyUpPtr);
    eh.addEventListener('keypress', FKeyPressPtr);
    eh.addEventListener('input', FInputPtr);
    eh.addEventListener('focus', FEnterPtr);
    eh.addEventListener('blur', FExitPtr);

    eh.addEventListener('touchstart', FTouchStartPtr);
    eh.addEventListener('touchmove', FTouchMovePtr);
    eh.addEventListener('touchend', FTouchEndPtr);
    eh.addEventListener('touchcancel', FTouchCancelPtr);

    eh.addEventListener('contextmenu', FContextMenuPtr);

    eh.addEventListener('dragstart', FDragStartPtr);
    eh.addEventListener('dragover', FDragOverPtr);
    eh.addEventListener('dragenter', FDragEnterPtr);
    eh.addEventListener('dragleave', FDragLeavePtr);
    eh.addEventListener('dragend', FDragEndPtr);
    eh.addEventListener('drop', FDragDropPtr);
  end;
end;

procedure TControl.BringToFront;
begin
  if Assigned(ElementHandle) and Assigned(ElementHandle.parentElement) then
  begin
    try
      ElementHandle.parentElement.appendChild(ElementHandle);
      if Assigned(Parent) then
        Parent.MoveLast(Self);
    except
    end;
  end;
end;

function TControl.GetChildContainer: TJSElement;
begin
  Result := FContainer;
end;

procedure TControl.GetChildren(Proc: TGetChildProc; Root: TComponent);
var
  I: Integer;
  Control: TControl;
begin
  for I := 0 to ControlCount - 1 do
  begin
    Control := Controls[I];
    if (Control.Owner = Root) or (Root = nil) then
      Proc(Control);
  end;
end;

function TControl.GetClientHeight: Integer;
begin
  Result := Height;
end;

function TControl.GetClientOrigin: TPoint;
var
  r: TJSDomRect;
begin
  Result := Point(0, 0);
  if Assigned(ElementHandle) then
  begin
    r := ElementHandle.getBoundingClientRect;
    Result := Point(Round(r.Left), Round(r.Top));
  end;
end;

function TControl.GetClientRect: TRect;
var
  css: string;
  p,brdr: integer;
begin
  css := Uppercase(GetStyle('border-width'));
  brdr := 0;

  p := pos('PX',css);
  if p > 0 then
  begin
    delete(css,p,2);
    val(css, brdr, p);
  end;

  Result := Rect(0, 0, GetWidth - 2* brdr, GetHeight - 2*brdr);
end;

function TControl.GetClientWidth: Integer;
begin
  Result := Width;
end;

function TControl.GetKeyChar(AValue: string): char;
var
  i: integer;
begin
  i := -1;
  Result := #0;

  case AValue of
    TJSKeyNames.BackSpace: i := 8;
    TJSKeyNames.Tab: i := 9;
    TJSKeyNames.Enter: i := 13;
    TJSKeyNames.Shift: i := 16;
    TJSKeyNames.Control: i := 17;
    TJSKeyNames.Alt: i := 18;
    TJSKeyNames.Pause: i := 19;
    TJSKeyNames.CapsLock: i := 20;
    TJSKeyNames.Escape: i := 27;
    TJSKeyNames.PageUp: i := 33;
    TJSKeyNames.PageDown: i := 34;
    TJSKeyNames._End: i := 35;
    TJSKeyNames.Home: i := 36;
    TJSKeyNames.ArrowLeft: i := 37;
    TJSKeyNames.ArrowUp: i := 38;
    TJSKeyNames.ArrowRight: i := 39;
    TJSKeyNames.ArrowDown: i := 40;
    TJSKeyNames.Insert: i := 45;
    TJSKeyNames.Delete: i := 46;

    TJSKeyNames.F1: i := 112;
    TJSKeyNames.F2: i := 113;
    TJSKeyNames.F3: i := 114;
    TJSKeyNames.F4: i := 115;
    TJSKeyNames.F5: i := 116;
    TJSKeyNames.F6: i := 117;
    TJSKeyNames.F7: i := 118;
    TJSKeyNames.F8: i := 119;
    TJSKeyNames.F9: i := 120;
    TJSKeyNames.F10: i := 121;
    TJSKeyNames.F11: i := 122;
    TJSKeyNames.F12: i := 123;
    TJSKeyNames.F13: i := 124;
    TJSKeyNames.F14: i := 125;
    TJSKeyNames.F15: i := 126;
    TJSKeyNames.F16: i := 127;
    TJSKeyNames.F17: i := 128;
    TJSKeyNames.F18: i := 129;
    TJSKeyNames.F19: i := 130;
    TJSKeyNames.F20: i := 131;
    else
    begin
      Result := AValue[1];
    end;
  end;

  if i <> -1 then
    Result := chr(i);
end;

function TControl.GetKeyCode(AValue: string; IgnoreCase: boolean = true): Integer;
var
  i: Integer;
begin
  i := -1;
  case AValue of
    'Up': i := 38;
    'Down': i := 40;
    'Left': i := 37;
    'Right': i := 39;
    '!': i := 56;
    '"': i := 51;
    '#': i := 51;
    '$': i := 186;
    '%': i := 192;
    '''': i := 52;
    '(': i := 53;
    ')': i := 219;
    '*': i := 106;
    '+': i := 107;
    '-': i := 109;
    ',': i := 44;
    '.': i := 46;
    '/': i := 111;
    '\': i := 226;
    '=': i := 187;
    '<': i := 226;
    '>': i := 226;
    TJSKeyNames.BackSpace: i := 8;
    TJSKeyNames.Tab: i := 9;
    TJSKeyNames.Enter: i := 13;
    TJSKeyNames.Shift: i := 16;
    TJSKeyNames.Control: i := 17;
    TJSKeyNames.Alt: i := 18;
    TJSKeyNames.Pause: i := 19;
    TJSKeyNames.CapsLock: i := 20;
    TJSKeyNames.Escape: i := 27;
    TJSKeyNames.PageUp: i := 33;
    TJSKeyNames.PageDown: i := 34;
    TJSKeyNames._End: i := 35;
    TJSKeyNames.Home: i := 36;
    TJSKeyNames.ArrowLeft: i := 37;
    TJSKeyNames.ArrowUp: i := 38;
    TJSKeyNames.ArrowRight: i := 39;
    TJSKeyNames.ArrowDown: i := 40;
    TJSKeyNames.Insert: i := 45;
    TJSKeyNames.Delete: i := 46;

    TJSKeyNames.F1: i := 112;
    TJSKeyNames.F2: i := 113;
    TJSKeyNames.F3: i := 114;
    TJSKeyNames.F4: i := 115;
    TJSKeyNames.F5: i := 116;
    TJSKeyNames.F6: i := 117;
    TJSKeyNames.F7: i := 118;
    TJSKeyNames.F8: i := 119;
    TJSKeyNames.F9: i := 120;
    TJSKeyNames.F10: i := 121;
    TJSKeyNames.F11: i := 122;
    TJSKeyNames.F12: i := 123;
    TJSKeyNames.F13: i := 124;
    TJSKeyNames.F14: i := 125;
    TJSKeyNames.F15: i := 126;
    TJSKeyNames.F16: i := 127;
    TJSKeyNames.F17: i := 128;
    TJSKeyNames.F18: i := 129;
    TJSKeyNames.F19: i := 130;
    TJSKeyNames.F20: i := 131;
    else
    begin
      if Assigned(AValue) and (Length(AValue) > 0) then
      begin
        i := Ord(AValue[1]);
        if IgnoreCase and (i >= 97) and (i <= 122)  then
          i := i and $DF
        else
          if (i >= 65) and (i <= 90) then
            i := i or $40
          else
          if i > 122 then
            i := i or $80;
      end;
    end;
  end;

  Result := i;
end;

function TControl.GetLeft: Integer;
begin
  Result := FLeft;
  if (Result = -1) and Assigned(ElementHandle) and not (csLoading in ComponentState) then
    Result := Round(ElementHandle.offsetLeft);
end;

function TControl.GetTop: Integer;
begin
  Result := FTop;
  if (Result = -1) and Assigned(ElementHandle) and not (csLoading in ComponentState) then
    Result := Round(ElementHandle.offsetTop);
end;

function TControl.GetTouchEventShiftState(Event: TJSTouchEvent): TShiftState;
begin
  Result := [];
  if Event.shiftKey then
    Result := Result + [ssShift];
  if Event.ctrlKey then
    Result := Result + [ssCtrl];
  if Event.altKey then
    Result := Result + [ssAlt];
end;

function TControl.GetWebClassName: string;
begin
  Result := ClassName;
  Delete(Result, 1, 1);
  Result := 'TWeb' + Result;
end;

function TControl.GetWidth: Integer;
var
  cr: TJSDOMRect;
begin
  Result := FWidth;

  if Assigned(ElementHandle) and not (csLoading in ComponentState) then
  begin
    if (Result = -1) then
    begin
      Result := Round(Int(ElementHandle.offsetWidth));
    end;

    if (WidthStyle <> ssAbsolute) then
    begin
      cr := ElementHandle.getBoundingClientRect;
      Result := Round(Int(cr.Right - cr.Left));
    end;
  end;
end;

function TControl.GetHeight: Integer;
var
  cr: TJSDOMRect;
begin
  Result := FHeight;

  if Assigned(ElementHandle) and not (csLoading in ComponentState) then
  begin
    if (Result = -1) then
    begin
      Result := Round(Int(ElementHandle.offsetHeight));
    end;

    if (HeightStyle <> ssAbsolute) then
    begin
      cr := ElementHandle.getBoundingClientRect;
      Result := Round(Int(cr.Bottom - cr.Top));
    end;
  end;
end;

procedure TControl.KeyDown(var Key: Word; Shift: TShiftState);
begin
  if Assigned(OnKeyDown) then
    OnKeyDown(Self, Key, Shift);
end;

procedure TControl.KeyPress(var Key: Char);
begin
  if Assigned(OnKeyPress) then
    OnKeyPress(Self, Key);
end;

procedure TControl.KeyUp(var Key: Word; Shift: TShiftState);
begin
  if Assigned(OnKeyUp) then
    OnKeyUp(Self, Key, Shift);
end;

function TControl.LayerHandleDoMouseEnter(Event: TJSMouseEvent): Boolean;
begin
  if (Event.buttons = 0) and Captured and not FMouseInsideLayer then
  begin
    ReleaseCapture;
    HandleDoMouseUp(Event);
    HandleDoMouseLeave(Event);
  end;
  FMouseInsideLayer := True;
  Result := true;
end;

function TControl.LayerHandleDoMouseLeave(Event: TJSMouseEvent): Boolean;
begin
  FMouseInsideLayer := False;
  Result := true;
end;

procedure TControl.Loaded;
var
  i: integer;
begin
  if not (csLoading in ComponentState) then
    Exit;

  inherited;

  for i := 0 to ControlCount - 1 do
  begin
    // handle relative child positions
    if (Controls[i].ElementPosition in [epRelative, epIgnore]) and not (Controls[i].IsLinked) and (Controls[i].ChildOrder >= 0) then
    begin
      if (Controls[i].ChildOrder < ChildContainer.childNodes.Length) then
      begin
        ChildContainer.insertBefore(Controls[i].Container, ChildContainer.childNodes[Controls[i].ChildOrder]);
      end;
    end;
    Controls[i].Loaded;
  end;

  // all remaining child controls set to Loaded
  for i := 0 to ComponentCount - 1 do
  begin
    if (csLoading in Components[i].ComponentState) then
      Components[i].Loaded;
  end;

  case Align of
    alClient:
    begin
      if Assigned(Parent) then
        SetBoundsInt(0, 0, Parent.FWidth, Parent.FHeight);
    end;
  end;

  Resize;
  UpdateElement;
end;

procedure TControl.LoadFromHTML(const HTML: string);
begin
  if Assigned(ElementHandle) then
    ElementHandle.outerHTML := HTML;
end;

procedure TControl.LoadState(AState: string);
begin

end;

function TControl.HandleAllocated: Boolean;
begin
  Result := True;
end;

function TControl.HandleDoClick(Event: TJSMouseEvent): Boolean;
begin
  FElementEvent := Event;
  if (eeClick in EventStopPropagation) then
    StopPropagation;
  if Enabled then
    Click;
  Result := True;
  FElementEvent := nil;
end;

function TControl.HandleDoContextMenu(Event: TJSMouseEvent): Boolean;
begin
  Result := true;
end;

function TControl.HandleDoDblClick(Event: TJSMouseEvent): Boolean;
begin
  FElementEvent := Event;
  if (eeDblClick in EventStopPropagation) then
    StopPropagation;
  if Enabled then
    DblClick;
  Result := True;
  FElementEvent := nil;
end;

function TControl.HandleDoDragDrop(Event: TJSDragEvent): Boolean;
begin
  FElementEvent := Event;
  Event.stopPropagation;
  if not FAcceptDrag then
    Event.preventDefault
  else
  begin
    if not IsInputControl then
      Event.preventDefault;
    DragDrop(DragObject, Event.clientX, Event.clientY);
  end;
  Result := FAcceptDrag;
  FElementEvent := nil;
end;

function TControl.HandleDoDragEnd(Event: TJSDragEvent): Boolean;
begin
  FElementEvent := Event;
  Event.stopPropagation;
  DoEndDrag(DragObject, Event.clientX, Event.clientY);
  Result := True;
  if (DragMode = dmManual) then
    DisableDrag;
  FElementEvent := nil;
end;

function TControl.HandleDoDragEnter(Event: TJSDragEvent): Boolean;
begin
  FElementEvent := Event;
  Event.stopPropagation;
  FAcceptDrag := IsInputControl;
  DragOver(DragObject, Event.clientX, Event.clientY, dsDragEnter, FAcceptDrag);
  if FAcceptDrag then
    Event.preventDefault;
  Result := not FAcceptDrag;
  FElementEvent := nil;
end;

function TControl.HandledoDragLeave(Event: TJSDragEvent): Boolean;
begin
  FElementEvent := Event;
  Event.stopPropagation;
  DragOver(DragObject, Event.clientX, Event.clientY, dsDragLeave, FAcceptDrag);
  Result := True;
  FElementEvent := nil;
end;

function TControl.HandleDoDragOver(Event: TJSDragEvent): Boolean;
begin
  FElementEvent := Event;
  Event.stopPropagation;
  DragOver(DragObject, Event.clientX, Event.clientY, dsDragMove, FAcceptDrag);
  if FAcceptDrag and (not IsInputControl or (Event.dataTransfer.items.length = 0)) then
    Event.preventDefault;
  Result := not FAcceptDrag;
  FElementEvent := nil;
end;

function TControl.HandleDoDragStart(Event: TJSDragEvent): Boolean;
var
  obj: TDragObject;
begin
  FElementEvent := Event;
  Event.stopPropagation;
  obj := nil;
  DoStartDrag(obj);
  if Assigned(obj) then
    DragObject := obj
  else
    DragObject := Self;
  Result := True;
end;

{$HINTS OFF}

procedure TControl.XYToClient(X,Y: single; var AClientX, AClientY: single);
var
  p: TControl;
  cr: TJSDOMRect;
  el: TJSHTMLElement;
  OrigX,OrigY: single;
  norel, formcont: boolean;
  r: TRect;
  cs: TJSCSSStyleDeclaration;
  csx, csy: integer;
  scrollx, scrolly: integer;
begin
  norel := True;
  formcont := False;

  scrollx := 0;
  scrolly := 0;

  if (ElementPosition = epAbsolute) then
  begin
    scrollx := document.body.scrollLeft + document.documentElement.scrollLeft;
    scrolly := document.body.scrollTop + document.documentElement.scrollTop;
    OrigX := X - Left;
    OrigY := Y - Top;
  end
  else
  begin
    norel := false;
    cr := ElementHandle.getBoundingClientRect;
    OrigX := X - cr.Left;
    OrigY := Y - cr.Top;
    AClientX := OrigX;
    AClientY := OrigY;
    Exit;
  end;

  AClientX := OrigX;
  AClientY := OrigY;

  p := Parent;

  if (Self is TCustomForm) and not Assigned(p) and Assigned(Container) then
  begin
    cr := Container.getBoundingClientRect;
    AClientX := AClientX - cr.Left;
    AClientY := AClientY - cr.Top;
  end;

  while Assigned(p) do
  begin
    if (p.IsLinked) then
    begin
      if (p is TCustomForm) then
      // is a form hosted in another HTML element
        cr := p.Container.getBoundingClientRect
      else
      // is control linked to HTML element
        cr := p.ElementHandle.getBoundingClientRect;

      // subtract the calculated position
      AClientX := AClientX - cr.Left;
      AClientY := AClientY - cr.Top;

      scrollx := 0;
      scrolly := 0;
    end
    else
    if (p is TCustomForm) then
    begin
      if ((p as TCustomForm).FormContainer <> '') then
      begin
        el := TJSHTMLElement(document.getElementById((p as TCustomForm).FormContainer));

        if Assigned(el) then
        begin
          formcont := true;
          cr := el.getBoundingClientRect;
          AClientX := AClientX - cr.Left;
          AClientY := AClientY - cr.Top;
        end;
      end;

      if Assigned((p as TCustomForm).Container) and ((p as TCustomForm).FormContainer = '') and {((p as TCustomForm).FormFileName <> '') and} ((p as TCustomForm).Popup) then
      begin
        cr := (p as TCustomForm).Container.getBoundingClientRect;
        AClientX := AClientX - cr.Left;
        AClientY := AClientY - cr.Top;
        r := (p as TCustomForm).ClientRect;
        AClientY := AClientY + r.Top;
        scrollx := 0;
        scrolly := 0;
      end;
    end
    else
    // restart calculation from bounding rectangle in case it is relative positioned
    if (p.ElementPosition in [epRelative, epIgnore]) then
    begin
      if Assigned(p.ElementHandle) then
      begin
        cr := p.ElementHandle.getBoundingClientRect;
        AClientX := OrigX - cr.Left;
        AClientY := OrigY - cr.Top;
        scrollx := 0;
        scrolly := 0;

        // as first relative element, take the BODY margins in account
        if norel then
        begin
          cs := window.getComputedStyle(document.documentElement);
          {$IFDEF PAS2JS}
          asm
            csx = parseInt(cs.marginLeft,10);
            csy = parseInt(cs.marginTop,10)
          end;
          {$ENDIF}

          AClientX := AClientX + csx;
          AClientY := AClientY + csy;
        end;

        norel := false;
      end;
    end
    else
    // only subtract for absolute positioned elements
    if (p.ElementPosition = epAbsolute) and norel then
    begin
      AClientX := AClientX - p.Left;
      AClientY := AClientY - p.Top;
      OrigX := AClientX;
      OrigY := AClientY;
    end;
    p := p.Parent;
  end;

  // take scroll in account when there was no relative element involved
  if (ElementPosition = epAbsolute) and not formcont then
  begin
    AClientX := AClientX + scrollx;
    AClientY := AClientY + scrolly;
  end;
end;

{$HINTS ON}

procedure TControl.ClientToXY(AClientX, AClientY: single; var X,Y: single);
var
  p: TControl;
  cr: TJSDOMRect;
begin
  X := AClientX + Left - document.body.scrollLeft - document.documentElement.scrollLeft;
  Y := AClientY + Top - document.body.scrollTop - document.documentElement.scrollTop;

  p := Parent;
  while Assigned(p) do
  begin
    if (p.IsLinked) then
    begin

      if (p is TCustomForm) then
      // is a form hosted in another HTML element
        cr := p.Container.getBoundingClientRect
      else
      // is control linked to HTML element
        cr := p.ElementHandle.getBoundingClientRect;

      // subtract the calculated position
      X := X + cr.Left;
      Y := Y + cr.Top;
    end;

    if (p is TCustomForm) then
    begin

      if Assigned((p as TCustomForm).Container) and ((p as TCustomForm).FormContainer = '') and ((p as TCustomForm).FormFileName <> '') and ((p as TCustomForm).Popup) then
      begin
        cr := (p as TCustomForm).Container.getBoundingClientRect;
        X := X + cr.Left;
        Y := Y + cr.Top;
      end;
    end;

    // only add for absolute positioned elements
    if (p.ElementPosition = epAbsolute) then
    begin
      X := X + p.Left;
      Y := Y + p.Top;
    end;

    p := p.Parent;
  end;
end;

function TControl.HandleDoMouseDown(Event: TJSMouseEvent): Boolean;
var
  l, t: Single;
  ss: TShiftState;
  mb: TMouseButton;
begin
  FElementEvent := Event;
  if (eeMouseDown in EventStopPropagation) then
    StopPropagation;

  if not CanFocus then
    PreventDefault;

  FMouseX := Round(Event.clientX);
  FMouseY := Round(Event.clientY);

  XYToClient(Event.clientX, Event.clientY, l, t);

  // click outside scroll area should not trigger mousedown
  if HasVertScrollBar and (l > Width - GetScrollBarWidth) then
    Exit;

  if HasHorzScrollBar and (t > Height - GetScrollBarHeight) then
    Exit;

  ss := GetMouseEventShiftState(Event);
  mb := GetMouseEventButton(Event);

  MouseDown(mb, ss, Trunc(l), Trunc(t));
  Result := True;
  FElementEvent := nil;
end;

function TControl.HandleDoMouseUp(Event: TJSMouseEvent): Boolean;
var
  l, t: Single;
  ss: TShiftState;
  mb: TMouseButton;
begin
  FElementEvent := Event;
  if (eeMouseUp in EventStopPropagation) then
    StopPropagation;

  XYToClient(Event.clientX, Event.clientY, l, t);

  // click outside scroll area should not trigger mousedown
  if HasVertScrollBar and (l > Width - GetScrollBarWidth) then
  begin
    FElementEvent := nil;
    Exit;
  end;

  if HasHorzScrollBar and (t > Height - GetScrollBarHeight) then
  begin
    FElementEvent := nil;
    Exit;
  end;

  ss := GetMouseEventShiftState(Event);
  mb := GetMouseEventButton(Event);

  if (DragMode = dmManual) then
    DisableDrag;

  MouseUp(mb, ss, Trunc(l), Trunc(t));
  Result := True;
  FElementEvent := nil;
end;

function TControl.HandleDoWheel(Event: TJSWheelEvent): Boolean;
var
  ss: TShiftState;
  h: Boolean;
  l,t: single;
begin
  FElementEvent := Event;
  StopPropagation;

  XYToClient(Event.clientX, Event.clientY, l, t);

  FWheelMousePos.X := round(l);
  FWheelMousePos.Y := round(t);

  ss := GetMouseWheelEventShiftState(Event);

  h := False;
  MouseWheel(ss, Trunc(-Event.deltay), h);

  Result := true;
  FElementEvent := nil;
end;

procedure TControl.ParentFontChanged;
var
  i: integer;
begin
  for i := 0 to ControlCount - 1 do
    begin
      if Controls[i].ParentFont then
      begin
        Controls[i].Font.Assign(Font);
        Controls[i].FontChanged;
        Controls[i].FParentFont := true;
        Controls[i].ParentFontChanged;
      end;
    end;
end;

procedure TControl.HandleFontChanged(Sender: TObject);
begin
  if (FUpdateCount = 0) then
  begin
    ParentFont := false;
    ParentFontChanged;
  end;

  FontChanged;
end;

function TControl.HasHorzScrollBar: Boolean;
begin
  Result := False;

  if Assigned(ElementHandle) then
     Result := ElementHandle.clientWidth < ElementHandle.scrollWidth;
end;

function TControl.HasVertScrollBar: Boolean;
begin
  Result := False;

  if Assigned(ElementHandle) then
     Result := ElementHandle.clientHeight < ElementHandle.scrollHeight;
end;

procedure TControl.Hide;
begin
  Visible := false;
end;

function TControl.HandleDoTouchStart(Event: TJSTouchEvent): Boolean;
var
  l,t: single;
  touch: TJSTouch;
  ss: TShiftState;
begin
  FElementEvent := Event;
  FLastElementEvent := Event;

  StopPropagation;

  if not FAllowTouch then
    PreventDefault;

  if Event.Touches.length > 0 then
  begin
    touch := Event.touches.Touches[0];

    XYToClient(touch.clientX, touch.clientY, l, t);

    ss := GetTouchEventShiftState(Event);

    if LinkTouchEvents then
    begin
      MouseDown(mbLeft, ss, Trunc(l), Trunc(t));
    end;

    TouchStart(Trunc(l), Trunc(t));
  end;
  Result := True;
  FElementEvent := nil;
end;

function TControl.HandleDoTouchMove(Event: TJSTouchEvent): Boolean;
var
  l,t: single;
  touch: TJSTouch;
  ss: TShiftState;
begin
  FElementEvent := Event;
  FLastElementEvent := Event;

  StopPropagation;

  if not FAllowTouch then
    PreventDefault;

  if Captured then
    PreventDefault;

  if Event.Touches.length > 0 then
  begin
    touch := Event.touches.Touches[0];

    XYToClient(touch.clientX, touch.clientY, l, t);

    ss := GetTouchEventShiftState(Event);

    if LinkTouchEvents then
      MouseMove(ss, Trunc(l), Trunc(t));

    TouchMove(Trunc(l), Trunc(t));
  end;
  FElementEvent := nil;
  Result := True;
end;

function TControl.HandleDoTouchEnd(Event: TJSTouchEvent): Boolean;
var
  l,t: single;
  touch: TJSTouch;
  ss: TShiftState;
begin
  FElementEvent := Event;

  if not FAllowTouch then
    PreventDefault;

  ReleaseCapture;
  StopPropagation;

  if Assigned(FLastElementEvent) and (TJSTouchEvent(FLastElementEvent).Touches.length > 0) then
  begin
    touch := TJSTouchEvent(FLastElementEvent).touches.Touches[0];

    XYToClient(touch.clientX, touch.clientY, l, t);

    ss := GetTouchEventShiftState(Event);

    if LinkTouchEvents then
    begin
      MouseUp(mbLeft, ss, Trunc(l), Trunc(t));
    end;

    TouchEnd(Trunc(l), Trunc(t));
  end;
  FElementEvent := nil;
  Result := True;
end;

function TControl.HandleDoTouchCancel(Event: TJSTouchEvent): Boolean;
var
  l,t: single;
  touch: TJSTouch;
  ss: TShiftState;
begin
  FElementEvent := Event;
  ReleaseCapture;
  StopPropagation;

  if Event.Touches.length > 0 then
  begin
    touch := Event.touches.Touches[0];

    XYToClient(touch.clientX, touch.clientY, l, t);

    ss := GetTouchEventShiftState(Event);

    if LinkTouchEvents then
    begin
      MouseUp(mbLeft, ss, Trunc(l), Trunc(t));
    end;

    TouchCancel(Trunc(l), Trunc(t));
  end;
  FElementEvent := nil;
  Result := True;
end;

procedure TControl.DragDrop(Source: TObject; X, Y: Integer);
begin
  if Assigned(OnDragDrop) then
    OnDragDrop(Self, Source, X, Y);
end;

procedure TControl.DragOver(Source: TObject; X, Y: Integer;
  State: TDragState; var Accept: Boolean);
begin
  if Assigned(OnDragOver) then
    OnDragOver(Self, Source, X, Y, State, Accept);
end;

procedure TControl.DoBoundsChange;
var
  b: boolean;
  frm: TCustomForm;
begin
  UpdateElementSize;
  RecreateCanvas;

  if FIsResizing then
    Exit;

  FIsResizing := true;

  InternalResize;

  DoRealign;

  b := false;

  frm := GetParentForm(Self);
  if Assigned(frm) then
  begin
    b := frm.IsResizing;
    b := b or (TControl(frm).FUpdateCount > 0);
  end;

  b := b or (FUpdateCount > 0);
  b := b or FIsAligning;

  if not b then
  begin
    UpdateChildAnchoring;
  end;

  FIsResizing := false;
end;

procedure TControl.DoEndDrag(Target: TObject; X, Y: Integer);
begin
  if Assigned(OnEndDrag) then
    OnEndDrag(Self, Target, X, Y);
end;

procedure TControl.DoStartDrag(var DragObject: TDragObject);
begin
  if Assigned(OnStartDrag) then
    OnStartDrag(Self, DragObject);
end;

function TControl.Focused: Boolean;
begin
  Result := FContainer = document.activeElement;
end;


procedure TControl.FontChanged;
begin
  UpdateElementVisual;
end;

function TControl.HandleDoMouseLeave(Event: TJSMouseEvent): Boolean;
begin
  if Captured then
    Exit;

  Event.stopPropagation;
  DoMouseLeave;
  Result := true;
end;

function TControl.HandleDoMouseEnter(Event: TJSMouseEvent): Boolean;
begin
  if Captured then
    Exit;

  Event.stopPropagation;
  DoMouseEnter;
  Result := true;
end;

function TControl.HandleDoMouseMove(Event: TJSMouseEvent): Boolean;
var
  l, t: Single;
  ss: TShiftState;
begin
  FElementEvent := Event;
  FCursorX := round(Event.clientX);
  FCursorY := round(Event.clientY);

  if (eeMouseMove in EventStopPropagation) then
    StopPropagation;

  XYToClient(Event.clientX, Event.clientY, l, t);

  ss := GetMouseEventShiftState(Event);

  MouseMove(ss, Trunc(l), Trunc(t));
  Result := True;
  FElementEvent := nil;
end;

function TControl.HandleDoKeyDown(Event: TJSKeyBoardEvent): Boolean;
var
  k: Word;
  ss: TShiftState;
  undef: boolean;
begin
  FElementEvent := Event;
  if (eeKeyDown in EventStopPropagation) then
    StopPropagation;

  {$IFDEF PAS2JS}
  asm
    undef = (Event.key == undefined);
  end;
  {$ENDIF}

  if not undef then
  begin
    k := GetKeyCode(Event.Key, true);
    ss := GetKeyboardEventShiftState(Event);
    KeyDown(k, ss);
  end;

  Result := True;
  if k = 0 then
    PreventDefault;

  FElementEvent := nil;
end;

function TControl.HandleDoKeyUp(Event: TJSKeyBoardEvent): Boolean;
var
  k: Word;
  ss: TShiftState;
  c: Char;
  undef: boolean;
begin
  FElementEvent := Event;
  if (eeKeyUp in EventStopPropagation) then
    StopPropagation;
  {$IFDEF PAS2JS}
  asm
    undef = (Event.key == undefined);
  end;
  {$ENDIF}

  if not undef then
  begin
    k := GetKeyCode(Event.Key, true);

    ss := GetKeyboardEventShiftState(Event);

    // #27 is not send as char in the browser
    if (k = 27) then
    begin
      c := #27;
      KeyPress(c);
    end;

    KeyUp(k, ss);
  end;

  Result := True;
  if k = 0 then
    PreventDefault;
  FelementEvent := nil;
end;

function TControl.HandleDoKeyPress(Event: TJSKeyBoardEvent): Boolean;
var
  c: Char;
begin
  FElementEvent := Event;
  if (eeKeyPress in EventStopPropagation) then
    StopPropagation;

  if IsKeyCharacter(Event.Key) then
  begin
    c := GetKeyChar(Event.Key);
    KeyPress(c);
  end;

  Result := True;
  if c = #0 then
    PreventDefault;
  FElementEvent := nil;
end;

function TControl.HandleDoInput(Event: TJSInputEvent): Boolean;
var
  c: Char;
  stemp: string;
begin
  //Note: On Android the OnKeyPress event is not triggered.
  //The OnInput event is used instead to trigger the OnKeyPress event.
  if IsAndroid then
  begin
    FElementEvent := Event;
    if (eeKeyPress in EventStopPropagation) then
      StopPropagation;

    c := #0;
    if Event.Data <> '' then
    begin
      stemp := Event.Data;

      if Assigned(stemp) then
        c := stemp[Length(stemp)]
      else
        c := #8;

      KeyPress(c);
    end;

    Result := True;
    if c = #0 then
      PreventDefault;
    FElementEvent := nil;
  end;
end;

function TControl.HandleDoEnter(Event: TJSFocusEvent): Boolean;
begin
  FElementEvent := Event;
  StopPropagation;

  DoEnter;
  Result := True;
  FElementEvent := nil;
  FGotFocus := True;
end;

function TControl.HandleDoExit(Event: TEventListenerEvent): Boolean;
begin
  DoExit;
  Result := True;
end;

procedure TControl.SetParent(AValue: TControl);
var
  isNew: boolean;
begin
  if Assigned(AValue) then
    AValue := AValue.CanAcceptChild(Self);

  isNew := FNew;

  if (FParent <> AValue) then
  begin
    if Assigned(FParent) then
      FParent.UnRegisterParent(Self);

    FPrevParent := FParent;
    FParent := AValue;
    FGotFocus := False;

    UpdateParent;

    if not (csDestroying in ComponentState) then
    begin
      if not isNew then
        UpdateElement;
      InitScript;
    end;

    if Assigned(FParent) then
    begin
      FParent.RegisterParent(Self);
    end;

    if (csLoading in ComponentState) and Assigned(Parent) and not (csLoading in Parent.ComponentState) then
      Loaded;

    if Assigned(AValue) and Assigned(Parent) and not Parent.IsUpdating then
      Realign;

    Invalidate;
  end;
end;

procedure TControl.SetParentColor(const Value: boolean);
begin
  if (FParentColor <> Value) and Value and Assigned(Parent) then
  begin
    Color := Parent.Color;
  end;

  FParentColor := Value;
end;

procedure TControl.SetParentComponent(Value: TComponent);
begin
  if (Parent <> Value) and (Value is TControl) then
   SetParent(TControl(Value));
end;

procedure TControl.SetParentElement(const Value: TJSHTMLElement);
begin
  if FNew then
  begin
    FElementPosition := epRelative;
    CreateControl;
    FParentElement := Value;

    if Assigned(FParentElement) then
    begin
      FParentElement.appendChild(Container);
    end;
  end
  else
  begin
    if Assigned(FParentElement) then
      FParentElement.removeChild(Container);

    FParentElement := Value;

    if Assigned(FParentElement) then
      FParentElement.appendChild(Container);

    UpdateElement;
  end;

  InitScript;
  Invalidate;
end;

procedure TControl.SetParentElementID(const Value: string);
var
  el: TJSHTMLElement;
begin
  FParentElementID := Value;

  el := TJSHTMLElement(document.getElementById(FParentElementID));

  if Assigned(el) then
    SetParentElement(el);
end;

procedure TControl.SetParentFont(const Value: boolean);
begin
  if (Value <> FParentFont) and (Value) and Assigned(Parent) then
  begin
    BeginUpdate;
    Font.Assign(Parent.Font);
    EndUpdate;
    UpdateElement;
  end;

  FParentFont := Value;
end;

procedure TControl.SetRequiredScripts(const Value: TStringList);
begin
  FRequiredScripts.Assign(Value);
end;

procedure TControl.SetRole(const Value: string);
begin
  FRole := Value;
  if Assigned(ElementHandle) then
  begin
    if Value = '' then
      ElementHandle.removeAttribute('role')
    else
      ElementHandle.setAttribute('role', Value);
  end;
end;

procedure TControl.Realign;
begin
  AlignControl(Self);
end;

procedure TControl.RecreateCanvas;
begin
end;

procedure TControl.RecreateElement;
begin
  if Assigned(Container) then
  begin
    UnbindEvents;
    Container.parentNode.removeChild(Container);
    FElement := nil;
    CreateControl;

    if Assigned(Parent) and Assigned(Parent.Container) then
      Parent.ChildContainer.appendChild(Container);
  end;
end;

procedure TControl.RegisterParent(AValue: TControl);
begin
  SetLength(FControls, Length(FControls) + 1);
  FControls[Length(FControls) - 1] := AValue;
end;

procedure TControl.ReleaseCapture;
var
  eh: TJSHTMLElement;
begin
  FCaptureDown := False;

  if FCaptured and Assigned(FLayer) then
  begin
    eh := TJSHTMLElement(FLayer);
    eh.removeEventListener('mouseenter', FLayerMouseEnterPtr);
    eh.removeEventListener('mouseleave', FLayerMouseLeavePtr);
    eh.removeEventListener('mousedown', FMouseDownPtr);
    eh.removeEventListener('mouseup', FMouseUpPtr);
    eh.removeEventListener('mousemove', FMouseMovePtr);
    eh.removeEventListener('touchstart', FTouchStartPtr);
    eh.removeEventListener('touchmove', FTouchMovePtr);
    eh.removeEventListener('touchend', FTouchEndPtr);
    eh.removeEventListener('touchcancel', FTouchEndPtr);

    document.body.removeChild(eh);
    FCaptured := False;
    FLayer := nil;
    UpdateElement;
  end;
end;

procedure TControl.ResetAnchoring;
begin
  FOrigRect := Rect(-1,-1,-1,-1);
  InitAnchoring;
end;

procedure TControl.Resize;
begin
  if Assigned(FOnResize) then
    FOnResize(Self);
end;

function TControl.AnchoringInitialized: boolean;
begin
  Result := (OrigRect.Left <> -1) or (OrigRect.Top <> -1);
end;

procedure TControl.InitAnchoring;
var
  i,dx,dy: integer;

  procedure SizeBorderOffset(AObject: TObject; var ax,ay: integer);
  var
    prop: TTypeMemberProperty;
  begin
    ax := 0;
    ay := 0;
    prop := GetPropInfo(AObject.ClassInfo, 'BorderStyle');
    if Assigned(prop) then
    begin
      i := GetOrdProp(AObject, prop);
      if i in [1,4,5] then
      begin
        ax := 4;
        ay := 4;
      end;
      if i in [2,3] then
      begin
        ax := 16;
        ay := 16;
      end;
    end;
  end;

begin
  if AnchoringInitialized then
    Exit;

  dx := 0;
  dy := 0;

  if (Self is TForm) then
  begin
    if (self as TForm).IsMiletus then
      SizeBorderOffset(Self, dx,dy);

    FOrigRect := Rect(0, 0, GetDesignWidth - dx, GetDesignHeight - dy);
  end
  else
  begin
    FOrigRect := Rect(GetDesignLeft, GetDesignTop, GetDesignLeft + GetDesignWidth, GetDesignTop + GetDesignHeight);
  end;

  if Assigned(Parent) then
  begin
    if (Parent is TForm) then
    begin
      if (Parent as TForm).IsMiletus then
      begin
        SizeBorderOffset(Parent, dx,dy);
      end;
      FOrigParentRect := Rect(0, 0, Parent.GetDesignWidth -dx, Parent.GetDesignHeight-dy)
    end
    else
    begin
      //InitAnchoring;
      if not Parent.AnchoringInitialized then
        Parent.InitAnchoring;

      FOrigParentRect := Rect(Parent.GetDesignLeft, Parent.GetDesignTop, Parent.GetDesignLeft + Parent.GetDesignWidth, Parent.GetDesignTop + Parent.GetDesignHeight);
    end;
  end;

  for i := 0 to ControlCount - 1 do
    Controls[i].InitAnchoring;
end;

procedure TControl.InjectCSS;
var
  cssname,css: string;
  cssmgr: TCSSCodeManager;
begin
  cssname := GetWebClassname;

  css := '';

  cssmgr := GetCSSManager;

  if Assigned(cssmgr) then
    css := cssmgr.GetClassCSS(cssname);

  if (css <> '') then
    AddControlStyle(css);
end;

procedure TControl.InternalResize;
begin
  if (csLoading in ComponentState) then
    Exit;

  Resize;
end;

procedure TControl.InternalUpdateParent;
var
  p: TControl;
begin
  p := FPrevParent;
  if FNew then
  begin
    CreateControl;

    if Assigned(Container) then
    begin
      if Assigned(Parent) and not Assigned(Parent.Container) then
        Parent.CreateControl;

      if (Parent <> p) and Assigned(p) and Assigned(p.Container) then
      begin
        if Container.parentNode = p.ChildContainer then
          p.ChildContainer.removeChild(Container);
      end;

      if Assigned(Parent) and Assigned(Parent.Container) then
      begin
        Parent.ChildContainer.appendChild(Container);
      end;
    end;
  end;
end;

function TControl.IsEnabled: boolean;
begin
  Result := FEnabled;
end;

function TControl.IsFocused: Boolean;
begin
  Result := FContainer = document.activeElement;
end;

function TControl.IsInputControl: Boolean;
begin
  Result := False;
end;

function TControl.IsKeyCharacter(AValue: string): Boolean;
begin
  case AValue of
    'Up',
    'Down',
    'Left',
    'Right',
    TJSKeyNames.Shift,
    TJSKeyNames.Control,
    TJSKeyNames.Alt,
    TJSKeyNames.Pause,
    TJSKeyNames.CapsLock,
    TJSKeyNames.PageUp,
    TJSKeyNames.PageDown,
    TJSKeyNames._End,
    TJSKeyNames.Home,
    TJSKeyNames.ArrowLeft,
    TJSKeyNames.ArrowUp,
    TJSKeyNames.ArrowRight,
    TJSKeyNames.ArrowDown,
    TJSKeyNames.Insert,
    TJSKeyNames.Delete,
    TJSKeyNames.F1,
    TJSKeyNames.F2,
    TJSKeyNames.F3,
    TJSKeyNames.F4,
    TJSKeyNames.F5,
    TJSKeyNames.F6,
    TJSKeyNames.F7,
    TJSKeyNames.F8,
    TJSKeyNames.F9,
    TJSKeyNames.F10,
    TJSKeyNames.F11,
    TJSKeyNames.F12,
    TJSKeyNames.F13,
    TJSKeyNames.F14,
    TJSKeyNames.F15,
    TJSKeyNames.F16,
    TJSKeyNames.F17,
    TJSKeyNames.F18,
    TJSKeyNames.F19,
    TJSKeyNames.F20: Result := False;
    else
      Result := True;
  end;
end;

function TControl.IsStructuralElement: boolean;
begin
  Result := false;
end;

procedure TControl.UnbindEvents;
var
  eh: TJSEventTarget;
begin
  if Assigned(ElementBindHandle) then
  begin
    eh := ElementBindHandle;

    eh.removeEventListener('wheel', FWheelPtr);
    eh.removeEventListener('click', FClickPtr);
    eh.removeEventListener('dblclick', FDblClickPtr);
    eh.removeEventListener('mousedown', FMouseDownPtr);
    eh.removeEventListener('mouseup', FMouseUpPtr);
    eh.removeEventListener('mousemove', FMouseMovePtr);
    eh.removeEventListener('mouseleave', FMouseLeavePtr);
    eh.removeEventListener('mouseenter', FMouseEnterPtr);
    eh.removeEventListener('keydown', FKeyDownPtr);
    eh.removeEventListener('keyup', FKeyUpPtr);
    eh.removeEventListener('keypress', FKeyPressPtr);
    eh.removeEventListener('focus', FEnterPtr);
    eh.removeEventListener('blur', FExitPtr);
    eh.removeEventListener('input', FInputPtr);

    eh.removeEventListener('touchstart', FTouchStartPtr);
    eh.removeEventListener('touchmove', FTouchMovePtr);
    eh.removeEventListener('touchend', FTouchEndPtr);
    eh.removeEventListener('touchcancel', FTouchEndPtr);

    eh.removeEventListener('contextmenu', FContextMenuPtr);
  end;
end;

procedure TControl.UnRegisterParent(AValue: TControl);
var
  i: Integer;
  flg: Boolean;
begin
  flg := false;

  for i := 0 to Length(FControls) - 1 do
  begin
    if (FControls[i] = AValue) then
    begin
      flg := True;
    end;

    if (flg) and (i < Length(FControls) - 1) then
      FControls[i] := FControls[i + 1];
  end;

  if flg then
    SetLength(FControls, Length(FControls) - 1);
end;

procedure TControl.UpdateAnchoring;
var
  dxr,dyr: integer;
  dxo,dyo: integer;
  dxw,dyw: integer;
  br,r: TRect;
begin
  if (csLoading in ComponentState) then
    Exit;

  if not FControlCreated then
    Exit;

  if (OrigRect.Left = -1) and (OrigRect.Top = -1) then
    InitAnchoring;

  if Assigned(Parent) and not Parent.AnchoringInitialized then
    Exit;

  if (Align <> alClient) then
  begin
    if Assigned(Parent) and not (Self is TForm) then
    begin
      r := Parent.BoundsRect;

      dxr := (r.Right - r.Left) - (FOrigParentRect.Right - FOrigParentRect.Left);
      dyr := (r.Bottom - r.Top) - (FOrigParentRect.Bottom - FOrigParentRect.Top);

      br := FOrigRect;

      dxo := 0;
      dyo := 0;
      dxw := 0;
      dyw := 0;

      if (akRight in Anchors) then
      begin
        if (akLeft in Anchors) then
          dxw := dxr
        else
          dxo := dxr;
      end;

      if (akBottom in Anchors) then
      begin
        if (akTop in Anchors) then
          dyw := dyr
        else
          dyo := dyr;
      end;

      if (akBottom in Anchors) or (akRight in Anchors) then
      begin
        SetBoundsInt(br.Left + dxo, br.Top + dyo, br.Right - br.Left + dxw, br.Bottom - br.Top  + dyw);
      end;
    end;
  end;

  UpdateChildAnchoring;
end;

procedure TControl.UpdateChildAnchoring;
var
  i: integer;
begin
  for i := 0 to ControlCount - 1 do
  begin
    Controls[i].UpdateAnchoring;
  end;
end;

procedure TControl.UpdateChildren(AControl: TControl);
var
  I: Integer;
  c: TControl;
begin
  if Assigned(AControl) and not (csDestroying in ComponentState) then
  begin
    if (AControl.Align <> alNone)  then
      AControl.DoRealign;

    if (AControl is TCustomControl) then
    begin
      (AControl as TCustomControl).RecreateCanvas;
      (AControl as TCustomControl).Invalidate;
    end;

    for I := 0 to AControl.ControlCount - 1 do
    begin
      c := AControl.Controls[I];
      UpdateChildren(c);
    end;
  end;
end;

procedure TControl.PersistinHTML;
var
  i: integer;
begin
  for i := 0 to ControlCount - 1 do
    Controls[i].PersistinHTML;
end;

procedure TControl.InitFromHTML;
var
  i: integer;
begin
  for i := 0 to ControlCount - 1 do
    Controls[i].InitFromHTML;
end;

procedure TControl.InitScript;
begin
  // script to initialize any javascript control
end;

procedure TControl.PreventDefault;
begin
  if Assigned(ElementEvent) then
    ElementEvent.preventDefault;
end;

procedure TControl.UpdateElement;
begin
  if FBlockUpdateElement or (FUpdateCount > 0) then
    Exit;

  if Assigned(ElementHandle) and (ElementHandle <> ContainerElement) and not IsUpdating then
  begin
    UpdateElementData;

    if not IsLinked then
    begin
      UpdateElementVisual;
    end;
  end;
end;

{$HINTS OFF}
procedure TControl.UpdateElementData;
var
  frm: TCustomForm;
  el: TJSHTMLElement;
  AToolTip, ToolTipClass, ToolTipPos: string;
  ToolTipPause, ToolTipHidePause: integer;
begin
  if Assigned(ElementHandle) then
  begin
    if not (csDesigning in ComponentState) then
    begin
      if Visible then
        GetElementStyle.setProperty('display', '')
      else
        GetElementStyle.setProperty('display', 'none');
    end;

    EnableTab;

    if IsEnabled then
      FContainer.removeAttribute('disabled')
    else
      FContainer.setAttribute('disabled','');

    if IsLinked and (Hint = '') then
      Exit;

    if (Role <> '') then
      FContainer['role'] := FRole;

    frm := GetParentForm(Self);
    if Assigned(frm) and (frm.CSSLibrary = cssBootstrap) and ShowHint and (Hint <> '') then
    begin
      el := ElementHandle;
      AToolTip := Hint;
      case Application.HintPosition of
      hpLeft: ToolTipPos := 'left';
      hpRight: ToolTipPos := 'right';
      hpTop: ToolTipPos := 'top';
      hpBottom: ToolTipPos := 'bottom';
      end;
      ToolTipClass := Application.HintClassName;
      ToolTipPause := Application.HintPause;
      ToolTipHidePause := Application.HintHidePause;
      asm
        this.FTooltip = new bootstrap.Tooltip(el, {
        title: AToolTip,
        placement: ToolTipPos,
        customClass: ToolTipClass,
        delay: { "show": ToolTipPause, "hide": ToolTipHidePause }
       });
      end;
    end
    else
    begin
      if ShowHint and (Hint <> '') then
         FContainer['title'] := Hint
      else
         FContainer.removeAttribute('title');
    end;

    if not IsEnabled then
      GetElementStyle.removeProperty('color')
    else // restore color when font color is used
      if ELementFont <> efCSS then
        GetElementStyle.setProperty('color',ColorToHTML(Font.Color));
  end;
end;
{$HINTS ON}

procedure TControl.UpdateElementSize;
var
  es: TJSCSSStyleDeclaration;
  offsLeft,offsTop,ow: integer;
  ctrl: TControl;
  cr: TJSDOMRect;

begin
  if FBlockUpdateElement or (FUpdateCount > 0) then
    Exit;

  if IsLinked then
    Exit;

  if Assigned(ElementHandle) and (ElementHandle <> ContainerElement) then
  begin
    es := GetElementStyle;

    offsLeft := 0;
    offsTop := 0;

    ctrl := Parent;
    while Assigned(ctrl) and (ElementPosition = epAbsolute) and (ctrl.ElementPosition <> epAbsolute) and not (ctrl is TCustomForm) do
    begin
      // found in the hierarchy a free floating control, so take its position in account
      if ctrl.IsLinked and Assigned(ctrl.ElementHandle) and (ctrl.ElementHandle['position'] <> 'absolute') then
      begin
        cr := ctrl.ElementHandle.getBoundingClientRect;
        offsLeft := Round(cr.Left);
        offsTop := Round(cr.Top);
        break;
      end;
      ctrl := ctrl.Parent;
    end;

    if Assigned(Parent) and (Parent.IsStructuralElement) then
    begin
      offsLeft := Parent.Left;
      offsTop := Parent.Top;
    end;

    if (ElementPosition = epAbsolute) then
    begin
      if FTop <> -1 then
        es.setProperty('top', IntToStr(FTop + offsTop) + 'px')
      else
        es.setProperty('top', '');

      if FLeft <> -1 then
        es.setProperty('left', IntToStr(FLeft + offsLeft) + 'px')
      else
        es.setProperty('left', '');
    end
    else
    begin
      es.removeProperty('top');
      es.removeProperty('left');
    end;

    if (FWidthStyle = ssAbsolute) then
    begin
      ow := GetOuterWidth;
      if FWidth <> -1 then
        es.setProperty('width', IntToStr(ow) + 'px')
      else
        es.removeProperty('width');
    end;

    if (FWidthStyle = ssAuto) then
    begin
      es.removeProperty('width');
    end;

    if (FHeightStyle = ssAbsolute) then
    begin
      if FHeight <> -1 then
        es.setProperty('height', IntToStr(GetOuterHeight) + 'px')
      else
        es.removeProperty('height');
    end;

    if (FHeightStyle = ssAuto) then
    begin
      es.removeProperty('height');
    end;

    if (FWidthStyle = ssPercent) then
    begin
      if FWidth <> -1 then
        es.setProperty('width', FormatProp('%.2f%%',[FWidthPercent]))
      else
        es.removeProperty('width');
    end;

    if (FHeightStyle = ssPercent) then
    begin
      if FHeight <> -1 then
        es.setProperty('height', FormatProp('%.2f%%',[FHeightPercent]))
      else
        es.removeProperty('height');
    end;

    if FElementPosition = epAbsolute then
      es.setProperty('position', 'absolute')
    else if FElementPosition = epRelative then
      es.setProperty('position', 'relative')
    else
      es.removeProperty('position');

    es.setProperty('box-sizing','border-box');
  end;
end;

procedure TControl.UpdateElementVisual;
var
  eh: TJSHTMLElement;
  es: TJSCSSStyleDeclaration;
  useCSS: boolean;
begin
  eh := ElementHandle;

  if not Assigned(eh) then
    Exit;

  if IsUpdating then
    Exit;

  es := GetElementStyle;

//  if (csLoading in ComponentState) then
//    Exit;

  if ParentFont and Assigned(Parent) then
  begin
    Font.OnChange := nil;
    Font.Assign(Parent.Font);
    Font.OnChange := HandleFontChanged;
  end;

  if ElementClassName = '' then
  begin
    if ClipChildren and (LowerCase(eh.tagName) <> cBodyTag) then
      es.setProperty('overflow', 'hidden')
    else
      es.setProperty('overflow', '');

    SetElementPointer(eh, Cursor);
  end;

  if Captured then
    TJSHTMLElement(FLayer).style.setProperty('cursor', es.getPropertyValue('cursor'));

  if (ElementClassName <> '') or CanShowFocus then
    es.setProperty('outline', '')
  else
    es.setProperty('outline', 'none');

  UpdateElementSize;

  case FTextDirection of
  tdDefault: es.removeProperty('direction');
  tdRightToLeft: es.setProperty('direction','rtl');
  tdLeftToRight: es.setProperty('direction','ltr');
  tdInherit: es.setProperty('direction','inherit');
  end;

  if (ElementClassName = '') and FNoUserSelect then
  begin
    es.setProperty('webkit-user-select', 'none');
    es.setProperty('moz-user-select', 'none');
    es.setProperty('khtml-user-select', 'none');
    es.setProperty('ms-user-select', 'none');
    es.setProperty('user-select', 'none');
    es.setProperty('-webkit-tap-highlight-color', 'transparent');
  end;

  SetElementColor(eh, Color);

  useCSS := not ((ElementClassName = '') and (ElementFont = efProperty) and not IsLinked);

  SetHTMLElementFont(eh, Font, useCSS);

  if not useCSS then
  begin
    if IsEnabled then
      es.setProperty('color', ColorToHtml(Font.Color));
  end
  else
  begin
    es.removeProperty('color');
  end;
end;

procedure TControl.UpdateParent;
begin
  InternalUpdateParent;

//  if (csLoading in ComponentState) and Assigned(Parent) and not (csLoading in Parent.ComponentState) then
//    Loaded;

  UpdateChildren(FPrevParent);
  UpdateChildren(Parent);
end;

procedure TControl.UpdateSize;
var
  i: integer;
begin
  UpdateElementSize;
  for i := 0 to ControlCount - 1 do
    Controls[i].UpdateSize;
end;

procedure TControl.UpdateControlSize(AWidth, AHeight: integer);
begin
  FWidth := AWidth;
  FHeight := AHeight;
end;

procedure TControl.VisibleChanged;
var
  i: integer;
begin
  for i := 0 to ControlCount - 1 do
  begin
    Controls[i].VisibleChanged;
  end;
end;

procedure TControl.VisibleChanging;
begin

end;

function TControl.GetBoundsRect: TRect;
begin
  Result.Left := Left;
  Result.Top := Top;
  Result.Right := Left + Width;
  Result.Bottom := Top + Height;
end;

function TControl.GetControlsCount: Integer;
begin
  Result := Length(FControls);
end;

function TControl.GetCSSManager: TCSSCodeManager;
var
  frm: TCustomForm;
  i: integer;
begin
  Result := nil;

  frm := GetParentForm(Self);

  if Assigned(frm) then
  begin
    for i := 0 to frm.ComponentCount - 1 do
    begin
      if frm.Components[i] is TCSSCodeManager then
      begin
        Result := frm.Components[i] as TCSSCodeManager;
      end;
    end;
  end;
end;

function TControl.GetDesignHeight: integer;
begin
  Result := FHeight;
end;

function TControl.GetDesignLeft: integer;
begin
  Result := FLeft;
end;

function TControl.GetDesignTop: integer;
begin
  Result := FTop;
end;

function TControl.GetDesignWidth: integer;
begin
  Result := FWidth;
end;

function TControl.GetContainer: TJSElement;
begin
  Result := FContainer;
end;

function TControl.GetControls(Index: Integer): TControl;
begin
  Result := FControls[Index];
end;

procedure TControl.DoEnter;
begin
  if Assigned(OnEnter) then
    OnEnter(Self);
end;

procedure TControl.DoExit;
begin
  if Assigned(OnExit) then
    OnExit(Self);
end;

{ TCustomControl }

procedure TCustomControl.CreateInitialize;
begin
  inherited;
  ControlStyle := ControlStyle + [csSetCaption];
  FBorderStyle := bsSingle;
  FBorderColor := clSilver;
  FPainting := false;
  FWidth := 100;
  FHeight := 25;
  FWidthPercent := 100;
  FHeightPercent := 100;
  FCustomBorder := false;
end;

procedure TCustomControl.BindElement;
begin
  if (Container.TagName <> 'CANVAS') and not FControlCreated then
  begin
    FElementCanvas := TJSHTMLCanvasElement(document.createElement('CANVAS'));
    Container.appendChild(FElementCanvas);
  end;
end;

procedure TCustomControl.BindEvents;
begin
  inherited;
end;

procedure TCustomControl.CreateControl;
begin
  inherited;
  RecreateCanvas;
end;

function TCustomControl.CreateElement: TJSElement;
begin
  FElementCanvas := TJSHTMLCanvasElement(document.createElement('CANVAS'));
  if csAcceptsControls in ControlStyle then
  begin
    Result := document.createElement('SPAN');
    Result.appendChild(FElementCanvas);
    if ElementID <> '' then
      FElementCanvas['id'] := ElementID + '_Canvas';
    FElementCanvas['zindex'] := '-1';
  end
  else
    Result := FElementCanvas;
end;

destructor TCustomControl.Destroy;
begin
  if Assigned(FCanvas) then
    FCanvas.Free;
  inherited;
end;

procedure TCustomControl.EndUpdate;
begin
  inherited;
  if FUpdateCount = 0 then
    Invalidate;
end;

function TCustomControl.Focused: boolean;
begin
  Result := inherited Focused;
end;

function TCustomControl.GetCanvas: TCanvas;
begin
  if not Assigned(FCanvas) then
  begin
    if not IsLinked then
      CreateControl
    else
    begin
      if not Assigned(FElementCanvas) then
        FElementCanvas := TJSHTMLCanvasElement(document.getElementById(ElementID));
      RecreateCanvas;
    end;

  end;
  Result := FCanvas;
end;

function TCustomControl.GetCanvasHeightOffset: Integer;
begin
  Result := 0;
end;

function TCustomControl.GetCanvasWidthOffset: Integer;
begin
  Result := 0;
end;

function TCustomControl.GetContext: TJSCanvasRenderingContext2D;
var
  ctx: TJSCanvasRenderingContext2D;
begin
  {$IFDEF PAS2JS}
  asm
    ctx = this.FElementCanvas.getContext("2d");
  end;
  {$ENDIF}

  Result := ctx;
end;

function TCustomControl.GetPixelRatio: Single;
var
  res: single;
begin
  {$IFDEF PAS2JS}
  asm
    var ctx = document.createElement("canvas").getContext("2d"),
        dpr = window.devicePixelRatio || 1,
        bsr = ctx.webkitBackingStorePixelRatio ||
              ctx.mozBackingStorePixelRatio ||
              ctx.msBackingStorePixelRatio ||
              ctx.oBackingStorePixelRatio ||
              ctx.backingStorePixelRatio || 1;
    res = dpr / bsr
  end;
  {$ENDIF}

  Result := res;
end;

procedure TCustomControl.Invalidate;
var
  px: Single;
  frm: TCustomForm;
begin
  inherited;

  if (Parent = nil) or (csDestroying in ComponentState) then
    Exit;

  if not Visible then
    Exit;

  if FUpdateCount > 0 then
    Exit;

  frm := GetParentForm(Self);
  if Assigned(frm) then
    if frm.IsUpdating then
      Exit;

  px := GetPixelRatio;
  if (px <> FPixelRatio) then
    RecreateCanvas;

  FPixelRatio := GetPixelRatio;

  FPainting := true;

  if Assigned(FCanvas) then
    FCanvas.Clear;

  Paint;
  FPainting := false;
end;

procedure TCustomControl.Loaded;
begin
  inherited;
  Invalidate;
end;

function TCustomControl.MakeScreenShot: TBitmap;
begin
  Result := TBitmap.Create;
  Result.OnChange := HandleScreenshot;
  Result.LoadFromCanvas(Canvas);
end;

procedure TCustomControl.HandleScreenShot(Sender: TObject);
begin
  if Assigned(OnScreenshot) then
    OnScreenshot(Self, TBitmap(Sender));
end;

procedure TCustomControl.Paint;
begin
  
end;

procedure TCustomControl.RecreateCanvas;
var
  px: Single;
  el: TJSHTMLCanvasElement;
  h, w: Integer;
begin
  if Assigned(FElementCanvas) then
  begin
    if not FPainting then
    begin
      if IsLinked then
      begin
        el := TJSHTMLCanvasElement(ElementHandle);
      end
      else
        el := TJSHTMLCanvasElement(document.getElementByID(GetID+'_Canvas'));

      // it is already found
      if Assigned(el) then
      begin
        FElementCanvas := el;
        FCanvas.Free;
        FCanvas := nil;
      end;

      px := GetPixelRatio;
      h := Height - GetCanvasHeightOffset;
      w := Width - GetCanvasWidthOffset;

      if ElementPosition = epRelative then
        FElementCanvas.style.setProperty('position', 'relative')
      else
        FElementCanvas.style.setProperty('position', 'absolute');

      FElementCanvas.style.setProperty('height', IntToStr(h) + 'px');
      FElementCanvas.style.setProperty('width', IntToStr(w) + 'px');
      FElementCanvas.height := Round(h * px);
      FElementCanvas.width := Round(w * px);
    end;

    if not Assigned(FCanvas) then
    begin
      FCanvas := TCanvas.Create(FElementCanvas);
    end;

    FCanvas.ApplyPixelRatio := True;
    FCanvas.SetTransform(1, 0, 0, 1, 0, 0);
  end;
end;

procedure TCustomControl.RenderDesigning(ACaption: string; AElement: TJSElement;
  AControl: TCustomControl; ADisplay: Boolean; AImage: string);
var
  LContainer, LLabel, LDiv: TJSHTMLElement;
  LHasLabel: boolean;
begin
  LContainer := TJSHTMLElement(AElement);
  LLabel := TJSHTMLElement(LContainer.firstChild);
  LHasLabel := Assigned(LLabel) and (LLabel.tagName = 'DIV') and (LLabel.getAttribute('data-design') = '1');

  if (csDesigning in AControl.ComponentState) and (ADisplay) and (ElementClassName = '') then
  begin
    LContainer.style.setProperty('border','1px solid gray');

    if AImage = '' then
      LContainer.style.setProperty('background-color','#F0F0F0')
    else
      LContainer.style.setProperty('background-color','white');

    if LHasLabel then
      Exit;

    LContainer.innerHTML := '';

    if AImage <> '' then
    begin
      LDiv := TJSHTMLElement(document.createElement('DIV'));
      LDiv.style.setProperty('background-color','white');
      LDiv.style.setProperty('background-repeat','no-repeat');
      LDiv.style.setProperty('background-position-y','center');
      LDiv.style.setProperty('background-image', 'url('+AImage+')');
      LDiv.style.setProperty('background-position-x','center');
      LDiv.style.setProperty('background-size','128px');
      LDiv.style.setProperty('top','50%');
      LDiv.style.setProperty('left','50%');
      LDiv.style.setProperty('height','70%');
      LContainer.appendChild(LDiv);
    end;

    LLabel := TJSHTMLElement(document.createElement('DIV'));
    LLabel.setAttribute('data-design','1');
    LLabel.innerHTML := ACaption;
    LLabel.style.setProperty('position','relative');
    LLabel.style.setProperty('top','50%');
    LLabel.style.setProperty('left','50%');
    LLabel.style.setProperty('display','inline-block');
    LLabel.style.setProperty('transform','translateX(-50%)');

    CustomBorder := True;
    LContainer.appendChild(LLabel);
  end
  else
  begin
    if LHasLabel then
    begin
      while Assigned(LContainer.firstChild) do
        LContainer.removeChild(LContainer.firstChild);

      LContainer.style.removeProperty('border');
      LContainer.style.removeProperty('background-color');
    end;
  end;
end;

procedure TCustomControl.Resize;
begin
  inherited;
  Invalidate;
end;

procedure TCustomControl.SetBorderColor(const AValue: TColor);
begin
  if (FBorderColor <> AValue) then
  begin
    FBorderColor := AValue;
    UpdateElement;
  end;
end;

procedure TCustomControl.SetBorderStyle(const AValue: TBorderStyle);
begin
  if FBorderStyle <> AValue then
  begin
    FBorderStyle := AValue;
    UpdateElement;
  end;
end;

procedure TCustomControl.SetCaption(const AValue: string);
begin
  FCaption := AValue;
  ControlStyle := ControlStyle - [csSetCaption];
end;

procedure TCustomControl.SetFocus;
begin
  inherited;
end;

procedure TCustomControl.SetName(const NewName: TComponentName);
var
  cs: TControlStyle;
  ownername: string;
begin
  ownername := '';

  if not (Assigned(VSIDE) and (csDesigning in ComponentState)) then
  begin
    if (Self is TFrame) then
    begin
      ownername := 'F' + FindUniqueName(Name) + '_';
    end;
  end;

  inherited SetName(ownername + NewName);

  if (csSetCaption in ControlStyle) and (csDesigning in ComponentState) and not (csLoading in ComponentState) then
  begin
    if Assigned(Owner) and (csLoading in Owner.ComponentState) then
      Exit;

    cs := ControlStyle;
    SetCaption(NewName);
    ControlStyle := cs;
  end;
end;

procedure TCustomControl.UpdateElementVisual;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    if not IsLinked and (ElementClassName = '') then
    begin
      if BorderStyle = bsSingle then // default border style
      begin
        if CustomBorder then
        begin
          GetElementStyle.setProperty('border-style', 'solid');
          GetElementStyle.setProperty('border-width', '1px');
          GetElementStyle.setProperty('border-color', ColorToHTML(BorderColor));
        end
        else
          GetElementStyle.setProperty('border-style', '')
      end
      else
        GetElementStyle.setProperty('border-style', 'none');
    end;

    if (ElementClassName <> '') then
    begin
      GetElementStyle.removeProperty('border-style');
      GetElementStyle.removeProperty('border-width');
      GetElementStyle.removeProperty('border-color');
    end;
  end;
end;

{ TScrollingGraphicControl }

procedure TScrollingGraphicControl.UpdateElement;
begin
  inherited;
  if Assigned(ElementHandle) then
    ElementHandle.style.setProperty('overflow', 'auto');
end;


function TScrollingGraphicControl.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');
end;

{ TMargins }

procedure TMargins.Assign(Source: TPersistent);
begin
  if Source is TMargins then
  begin
    FLeft := (Source as TMargins).Left;
    FTop := (Source as TMargins).Top;
    FBottom := (Source as TMargins).Bottom;
    FRight := (Source as TMargins).Right;
  end
  else
    inherited;
end;

constructor TMargins.Create;
begin
  FLeft := 3;
  FTop := 3;
  FBottom := 3;
  FRight := 3;
end;

{ TControlManager }

constructor TControlManager.Create(AOwner: TComponent);
begin
  inherited;
  FInstanceCount := 0;
end;

function TControlManager.GetInstanceNumber: integer;
begin
  Inc(FInstanceCount);
  Result := FInstanceCount;
end;

procedure TControlManager.Reset;
begin
  FInstanceCount := 0;
end;

{ TCSSCodeFragment }

constructor TCSSCodeFragment.Create(Collection: TCollection);
begin
  inherited;
  FCSS := TStringList.Create;
end;

destructor TCSSCodeFragment.Destroy;
begin
  FCSS.Free;
  inherited;
end;

procedure TCSSCodeFragment.SetCSS(const Value: TStringList);
begin
  FCSS.Assign(Value);
end;

{ TCSSCodeFragments }

function TCSSCodeFragments.Add: TCSSCodeFragment;
begin
  Result := TCSSCodeFragment(inherited Add);
end;

constructor TCSSCodeFragments.Create(AOwner: TComponent);
begin
  inherited Create(AOwner, TCSSCodeFragment);
end;

function TCSSCodeFragments.GetItemEx(Index: integer): TCSSCodeFragment;
begin
  Result := TCSSCodeFragment(inherited Items[Index]);
end;

function TCSSCodeFragments.Insert(Index: integer): TCSSCodeFragment;
begin
  Result := TCSSCodeFragment(inherited Insert(Index));
end;

procedure TCSSCodeFragments.SetItemEx(Index: integer;
  const Value: TCSSCodeFragment);
begin
  inherited Items[Index] := Value;
end;

{ TCSSCodeManager }

constructor TCSSCodeManager.Create(AOwner: TComponent);
begin
  inherited;
  FCSSFragments := TCSSCodeFragments.Create(Self);
  FCSSFragments.PropName := 'CSSFragments';
end;

destructor TCSSCodeManager.Destroy;
begin
  FCSSFragments.Free;
  inherited;
end;

function TCSSCodeManager.GetClassCSS(AClassname: string): string;
var
  CSSCodeFragment: TCSSCodeFragment;
begin
  Result := '';

  CSSCodeFragment := GetClassFragment(AClassName);

  if Assigned(CSSCodeFragment) then
    Result := CSSCodeFragment.CSS.Text;
end;

function TCSSCodeManager.GetClassFragment(AClassname: string): TCSSCodeFragment;
var
  i: integer;
begin
  Result := nil;
  for i := 0 to CSSFragments.Count - 1 do
  begin
    if CSSFragments.Items[i].ControlClassName = AClassname then
      Result := CSSFragments.Items[i];
  end;
end;

procedure TCSSCodeManager.SetCSSFragments(const Value: TCSSCodeFragments);
begin
  FCSSFragments.Assign(Value);
end;

{ TjQueryCustomContol }

function TjQueryCustomControl.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');
end;

procedure TjQueryCustomControl.CreateInitialize;
begin
  inherited;
  FIsInitialized := false;
end;

function TjQueryCustomControl.GetJQID: string;
begin
  Result := '#' + GetID;
end;

procedure TjQueryCustomControl.InitJQuery;
begin
  //
end;

procedure TjQueryCustomControl.InitJQueryOnce;
begin
  if IsUpdating then
    Exit;

  if FIsInitialized then
    Exit;

  FIsInitialized := true;
  InitJQuery;
end;

procedure TjQueryCustomControl.Loaded;
begin
  inherited;
  InitJQueryOnce;
end;

procedure TjQueryCustomControl.SetParent(AValue: TControl);
begin
  inherited;
  InitJQueryOnce;
end;

{ TPadding }

procedure TPadding.Assign(Source: TPersistent);
begin
  if (Source is TPadding) then
  begin
    FLeft := (Source as TPadding).Left;
    FRight := (Source as TPadding).Right;
    FTop := (Source as TPadding).Top;
    FBottom := (Source as TPadding).Bottom;
  end;
end;

constructor TPadding.Create;
begin
  inherited;
  FLeft := 0;
  FTop := 0;
  FRight := 0;
  FBottom := 0;
end;

procedure TPadding.DoChange;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

procedure TPadding.SetBottom(const Value: integer);
begin
  if (FBottom <> Value) then
  begin
    FBottom := Value;
    DoChange;
  end;
end;

procedure TPadding.SetLeft(const Value: integer);
begin
  if (FLeft <> Value) then
  begin
    FLeft := Value;
    DoChange;
  end;
end;

procedure TPadding.SetRight(const Value: integer);
begin
  if (FRight <> Value) then
  begin
    FRight := Value;
    DoChange;
  end;
end;

procedure TPadding.SetTop(const Value: integer);
begin
  if (FTop <> Value) then
  begin
    FTop := Value;
    DoChange;
  end;
end;

{ TCustomHTMLDiv }

function TCustomHTMLDiv.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');
end;

{ TControlScrollBar }

constructor TControlScrollBar.Create(AControl: TControl; AKind: TScrollBarKind);
begin
  inherited;
  FControl := AControl;
  FKind := AKind;
end;

function TControlScrollBar.IsScrollBarVisible: boolean;
begin
  Result := false;

  if Assigned(FControl.ELementHandle) then
  begin
    if Kind = sbVertical then
      Result := TJSHTMLElement(FControl.ElementHandle).ScrollHeight > TJSHTMLElement(FControl.ElementHandle).ClientHeight
    else
      Result := TJSHTMLElement(FControl.ElementHandle).ScrollWidth > TJSHTMLElement(FControl.ElementHandle).ClientWidth;
  end;
end;

function TControlScrollBar.Range: integer;
begin
  Result := 0;
  if Assigned(FControl.ELementHandle) then
  begin
    if Kind = sbVertical then
      Result := TJSHTMLElement(FControl.ElementHandle).ScrollHeight
    else
      Result := TJSHTMLElement(FControl.ElementHandle).ScrollWidth;
  end;
end;

function TControlScrollBar.ScrollPos: integer;
begin
  Result := 0;
  if Assigned(FControl.ELementHandle) then
  begin
    if Kind = sbVertical then
      Result := FControl.ElementHandle.scrollTop
    else
      Result := FControl.ElementHandle.scrollLeft;
  end;
end;

initialization
  ScrollBW := 0;
  ScrollBH := 0;
  DragObject := nil;
  ControlManager := TControlManager.Create(nil);

end.
