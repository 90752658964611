unit thankYou;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.WebCtrls;

type
  TThankYouForm = class(TForm)
    installButton: TButton;
    closeButton: TButton;
    WelcomeMessageSpan: THTMLSpan;
    procedure WebFormCreate(Sender: TObject);
    procedure closeButtonClick(Sender: TObject);
    procedure installButtonClick(Sender: TObject);
    procedure WebFormHashChange(Sender: TObject; oldURL, newURL: string);
  private
    FInstallURL: string;
    procedure SetWelcomeMessage(const Value: string);
    { Private declarations }
  public
    { Public declarations }
    property InstallURL: string read FInstallURL write FInstallURL;
    property WelcomeMessage: string write SetWelcomeMessage;
  protected procedure LoadDFMValues; override; end;

var
  ThankYouForm: TThankYouForm;

implementation

{$R *.dfm}

procedure TThankYouForm.WebFormCreate(Sender: TObject);
begin
   installButton.Visible := Application.IsMobile;
end;

procedure TThankYouForm.closeButtonClick(Sender: TObject);
begin
  Close;
end;

procedure TThankYouForm.installButtonClick(Sender: TObject);
begin
  if not Application.IsMobile then
     ShowMessage('This option is only available on your mobile device')
  else
     Application.Navigate(FInstallURL);
end;

procedure TThankYouForm.SetWelcomeMessage(const Value: string);
begin
 WelcomeMessageSpan.HTML.Text := Value;
end;

procedure TThankYouForm.WebFormHashChange(Sender: TObject; oldURL, newURL:
    string);
begin
 if window.location.hash = '' then
    Close;
end;

procedure TThankYouForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  installButton := TButton.Create('installButton');
  closeButton := TButton.Create('closeButton');
  WelcomeMessageSpan := THTMLSpan.Create('WelcomeMessage');

  installButton.BeforeLoadDFMValues;
  closeButton.BeforeLoadDFMValues;
  WelcomeMessageSpan.BeforeLoadDFMValues;
  try
    Name := 'ThankYouForm';
    Width := 640;
    Height := 480;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnHashChange', 'WebFormHashChange');
    installButton.SetParentComponent(Self);
    installButton.Name := 'installButton';
    installButton.Left := 200;
    installButton.Top := 256;
    installButton.Width := 153;
    installButton.Height := 25;
    installButton.Caption := 'Add to your wallet';
    installButton.ElementClassName := 'btn btn-light';
    installButton.ElementFont := efCSS;
    installButton.HeightStyle := ssAuto;
    installButton.HeightPercent := 100.000000000000000000;
    installButton.WidthPercent := 100.000000000000000000;
    SetEvent(installButton, Self, 'OnClick', 'installButtonClick');
    closeButton.SetParentComponent(Self);
    closeButton.Name := 'closeButton';
    closeButton.Left := 200;
    closeButton.Top := 320;
    closeButton.Width := 96;
    closeButton.Height := 25;
    closeButton.Caption := 'Close';
    closeButton.ChildOrder := 1;
    closeButton.ElementClassName := 'btn btn-light';
    closeButton.ElementFont := efCSS;
    closeButton.ElementPosition := epIgnore;
    closeButton.HeightStyle := ssAuto;
    closeButton.HeightPercent := 100.000000000000000000;
    closeButton.WidthStyle := ssAuto;
    closeButton.WidthPercent := 100.000000000000000000;
    SetEvent(closeButton, Self, 'OnClick', 'closeButtonClick');
    WelcomeMessageSpan.SetParentComponent(Self);
    WelcomeMessageSpan.Name := 'WelcomeMessageSpan';
    WelcomeMessageSpan.Left := 176;
    WelcomeMessageSpan.Top := 160;
    WelcomeMessageSpan.Width := 100;
    WelcomeMessageSpan.Height := 40;
    WelcomeMessageSpan.HeightStyle := ssAuto;
    WelcomeMessageSpan.WidthStyle := ssAuto;
    WelcomeMessageSpan.ChildOrder := 2;
    WelcomeMessageSpan.ElementPosition := epIgnore;
    WelcomeMessageSpan.ElementFont := efCSS;
    WelcomeMessageSpan.Role := '';
  finally
    installButton.AfterLoadDFMValues;
    closeButton.AfterLoadDFMValues;
    WelcomeMessageSpan.AfterLoadDFMValues;
  end;
end;

end.