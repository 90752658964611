unit mainDataModule;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Modules,
  XData.Web.Connection,
  XData.Web.Client;

type
  TMainData = class(TDataModule)
    WebClient: TXDataWebClient;
    DataConnection: TXDataWebConnection;
    procedure WebDataModuleCreate(Sender: TObject);
  private
    FXDataURL: string;
    { Private declarations }
    [Async]
    procedure LoadConfig; async;
    procedure SetXDataURL(const Value: string);
  public
    { Public declarations }
    property XDataURL: string read FXDataURL write SetXDataURL;
  protected procedure LoadDFMValues; override; end;

var
  MainData: TMainData;

implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}
{$R *.dfm}

uses
  XData.Web.Response,
  XData.Web.Request;

procedure TMainData.SetXDataURL(const Value: string);
begin
  FXDataURL := Value;
  DataConnection.URL := FXDataURL;
  DataConnection.Connected := True;
end;

procedure TMainData.WebDataModuleCreate(Sender: TObject);
begin
  LoadConfig;
end;

{ TDataModule1 }

procedure TMainData.LoadConfig;

 Procedure OnSuccess(Response: IHttpResponse);
  Var
    Obj: TJSObject;
    lResponse: String;
  Begin
      If Response.StatusCode = 200 Then
      Begin
        lResponse := Response.ContentAsText;
        Obj := TJSObject(TJSJSON.parse(lResponse));

        If JS.toString(Obj['BaseUrl']) <> '' Then
           XDataURL := JS.toString(Obj['BaseUrl']);

      End;
  End;

  Procedure OnError;
  Begin

  End;

var
  Response: IHttpResponse;
  Conn: TXDataWebConnection;
  Obj: TJSObject;
  lResponse: string;
begin

  Conn := TXDataWebConnection.Create(nil);
  try
      Conn.SendRequest(THttpRequest.Create('config/config.json'), @OnSuccess, @OnError);

//    Response := Await(IHttpResponse, Conn.SendRequest(THttpRequest.Create('config/config.json')));

//    if Response.StatusCode = 200 then
//    begin
//      lResponse := Response.ContentAsText;
//      Obj := TJSObject(TJSJSON.parse(lResponse));
//      FXDataURL := JS.toString(Obj['XDataUrl']);
//    end;

  finally
    Conn.Free;
  end;
end;

procedure TMainData.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebClient := TXDataWebClient.Create(Self);
  DataConnection := TXDataWebConnection.Create(Self);

  WebClient.BeforeLoadDFMValues;
  DataConnection.BeforeLoadDFMValues;
  try
    Name := 'MainData';
    SetEvent(Self, 'OnCreate', 'WebDataModuleCreate');
    Height := 150;
    Width := 215;
    WebClient.SetParentComponent(Self);
    WebClient.Name := 'WebClient';
    WebClient.Connection := DataConnection;
    WebClient.Left := 40;
    WebClient.Top := 88;
    DataConnection.SetParentComponent(Self);
    DataConnection.Name := 'DataConnection';
    DataConnection.URL := 'http://localhost:2018/passkit';
    DataConnection.Left := 40;
    DataConnection.Top := 24;
  finally
    WebClient.AfterLoadDFMValues;
    DataConnection.AfterLoadDFMValues;
  end;
end;

end.
