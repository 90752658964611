unit Enrolment;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Vcl.Controls,
  WEBLib.WebCtrls;

type
  TEnrolmentForm = class(TForm)
    firstName: TEdit;
    lastName: TEdit;
    emailAddress: TEdit;
    mobileNumber: TEdit;
    enrolButton: TButton;
    company: TEdit;
    messageBlock: THTMLSpan;
    procedure emailAddressExit(Sender: TObject);
    procedure enrolButtonClick(Sender: TObject);
    procedure firstNameExit(Sender: TObject);
  private
    { Private declarations }
    [async]
    procedure LoadThankYou(const InstallURL, AMessage: string); async;
    [async]
    procedure JoinUp; async;
    procedure ValidateForm;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  EnrolmentForm: TEnrolmentForm;

implementation

{$R *.dfm}

uses
  XData.Web.Client,
  mainDataModule,
  thankYou, SMX.Web.Document.Utils, SMX.Web.Utils;

procedure TEnrolmentForm.emailAddressExit(Sender: TObject);
var lValue: String;
begin
  lValue := Trim(emailAddress.Text);
  if lValue = '' then
  begin
     TDocUtils.setControlValidity('emailAddress', vsInvalid);
  enrolButton.Enabled := False;
  end
  else if not TUtils.IsEmailValid(lValue) then
  begin
       TDocUtils.setControlValidity('emailAddress', vsInvalid);
     enrolButton.Enabled := False;
  end
  else
    ValidateForm;
end;

procedure TEnrolmentForm.enrolButtonClick(Sender: TObject);
begin
  JoinUp;
end;

procedure TEnrolmentForm.firstNameExit(Sender: TObject);
begin
  ValidateForm;
end;

procedure TEnrolmentForm.JoinUp;
var
  lRetval: TXDataClientResponse;
  lResponse: TJSObject;
  lMemberId, lOutcome, lMessage: string;
begin

  lRetval := await(TXDataClientResponse, mainData.WebClient.RawInvokeAsync('IPasskitService.EnrolMember',
    [firstName.Text, lastName.Text, emailAddress.Text, mobileNumber.Text, company.Text]));

  lResponse := lRetval.ResultAsObject;

  lOutcome := JS.toString(lResponse['Outcome']);

  if lOutcome = 'responseUpdated' then
  begin
    lMemberId := JS.toString(lResponse['Value']);
    lMessage := 'You already have a card issued and, if needed, the expiry date has been extended.';
    if Application.IsMobile then
       lMessage := lMessage + ' If you need to reinstall your card then you can click the install button below.';
  end
  else if lOutcome = 'responseOK' then
  begin
    lMemberId := JS.toString(lResponse['Value']);
    lMessage := 'Many thanks for registering, you will soon receive an email which will enable you to install your card into your phone wallet.';
    if Application.IsMobile then
       lMessage := lMessage + ' You can install your card right now by clicking the install button below.';
  end
  else if (lOutcome = 'responseError') or (lOutcome = 'responseException') then
  begin
    MessageBlock.HTML.Text := '<span class="problemHeader">We''re sorry there''s been a problem. Please try later.</span><br /><br />' +
      '<span class="problemDetail">' + JS.toString(lResponse['Value']) +  '</span>';
    Exit;
  end;

  LoadThankYou('https://pub1.pskt.io/' + lMemberId, lMessage);
end;

procedure TEnrolmentForm.LoadThankYou(const InstallURL, AMessage: string);
var
  thanks: TThankYouForm;
begin
  thanks := TThankYouForm.Create(self);
  window.location.hash := 'thankyou';
  await(TThankYouForm, thanks.load());
  thanks.InstallURL := InstallURL;
  thanks.WelcomeMessage := AMessage;
  try
    await(TModalResult, thanks.Execute);
    firstName.Text := '';
    lastName.Text := '';
    emailAddress.Text := '';
    mobileNumber.Text := '';
    company.Text := '';
  finally
    thanks.Free;
    thanks := nil;
  end;
end;

procedure TEnrolmentForm.ValidateForm;
var lOK: Boolean;
    lValue: string;
begin
  lOK := (Trim(firstName.Text) <> '') and (Trim(lastName.Text) <> '');

  lValue := Trim(emailAddress.Text);
  if lValue = '' then
     lOK := False
  else
  begin
    if not TUtils.IsEmailValid(lValue) then
       lOK := False;
  end;

  enrolButton.Enabled := lOK;

end;

procedure TEnrolmentForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  firstName := TEdit.Create('firstName');
  lastName := TEdit.Create('lastName');
  emailAddress := TEdit.Create('emailAddress');
  mobileNumber := TEdit.Create('mobileNumber');
  enrolButton := TButton.Create('enrolButton');
  company := TEdit.Create('company');
  messageBlock := THTMLSpan.Create('messageBlock');

  firstName.BeforeLoadDFMValues;
  lastName.BeforeLoadDFMValues;
  emailAddress.BeforeLoadDFMValues;
  mobileNumber.BeforeLoadDFMValues;
  enrolButton.BeforeLoadDFMValues;
  company.BeforeLoadDFMValues;
  messageBlock.BeforeLoadDFMValues;
  try
    Name := 'EnrolmentForm';
    Width := 640;
    Height := 480;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    firstName.SetParentComponent(Self);
    firstName.Name := 'firstName';
    firstName.Left := 128;
    firstName.Top := 88;
    firstName.Width := 121;
    firstName.Height := 22;
    firstName.ElementClassName := 'form-control';
    firstName.ElementFont := efCSS;
    firstName.HeightStyle := ssAuto;
    firstName.HeightPercent := 100.000000000000000000;
    firstName.WidthStyle := ssAuto;
    firstName.WidthPercent := 100.000000000000000000;
    SetEvent(firstName, Self, 'OnExit', 'firstNameExit');
    lastName.SetParentComponent(Self);
    lastName.Name := 'lastName';
    lastName.Left := 128;
    lastName.Top := 116;
    lastName.Width := 121;
    lastName.Height := 22;
    lastName.ChildOrder := 1;
    lastName.ElementClassName := 'form-control';
    lastName.ElementFont := efCSS;
    lastName.HeightStyle := ssAuto;
    lastName.HeightPercent := 100.000000000000000000;
    lastName.WidthStyle := ssAuto;
    lastName.WidthPercent := 100.000000000000000000;
    SetEvent(lastName, Self, 'OnExit', 'firstNameExit');
    emailAddress.SetParentComponent(Self);
    emailAddress.Name := 'emailAddress';
    emailAddress.Left := 128;
    emailAddress.Top := 196;
    emailAddress.Width := 121;
    emailAddress.Height := 22;
    emailAddress.ChildOrder := 2;
    emailAddress.ElementClassName := 'form-control';
    emailAddress.ElementFont := efCSS;
    emailAddress.HeightStyle := ssAuto;
    emailAddress.HeightPercent := 100.000000000000000000;
    emailAddress.WidthStyle := ssAuto;
    emailAddress.WidthPercent := 100.000000000000000000;
    SetEvent(emailAddress, Self, 'OnExit', 'emailAddressExit');
    mobileNumber.SetParentComponent(Self);
    mobileNumber.Name := 'mobileNumber';
    mobileNumber.Left := 128;
    mobileNumber.Top := 224;
    mobileNumber.Width := 121;
    mobileNumber.Height := 22;
    mobileNumber.ChildOrder := 2;
    mobileNumber.ElementClassName := 'form-control';
    mobileNumber.ElementFont := efCSS;
    mobileNumber.HeightStyle := ssAuto;
    mobileNumber.HeightPercent := 100.000000000000000000;
    mobileNumber.WidthStyle := ssAuto;
    mobileNumber.WidthPercent := 100.000000000000000000;
    SetEvent(mobileNumber, Self, 'OnExit', 'firstNameExit');
    enrolButton.SetParentComponent(Self);
    enrolButton.Name := 'enrolButton';
    enrolButton.Left := 128;
    enrolButton.Top := 272;
    enrolButton.Width := 96;
    enrolButton.Height := 25;
    enrolButton.Caption := 'Join';
    enrolButton.ChildOrder := 4;
    enrolButton.ElementClassName := 'btn btn-light';
    enrolButton.ElementFont := efCSS;
    enrolButton.Enabled := False;
    enrolButton.HeightStyle := ssAuto;
    enrolButton.HeightPercent := 100.000000000000000000;
    enrolButton.WidthPercent := 100.000000000000000000;
    SetEvent(enrolButton, Self, 'OnClick', 'enrolButtonClick');
    company.SetParentComponent(Self);
    company.Name := 'company';
    company.Left := 128;
    company.Top := 156;
    company.Width := 121;
    company.Height := 22;
    company.ChildOrder := 2;
    company.ElementClassName := 'form-control';
    company.ElementFont := efCSS;
    company.HeightStyle := ssAuto;
    company.HeightPercent := 100.000000000000000000;
    company.WidthStyle := ssAuto;
    company.WidthPercent := 100.000000000000000000;
    SetEvent(company, Self, 'OnExit', 'firstNameExit');
    messageBlock.SetParentComponent(Self);
    messageBlock.Name := 'messageBlock';
    messageBlock.Left := 320;
    messageBlock.Top := 128;
    messageBlock.Width := 100;
    messageBlock.Height := 41;
    messageBlock.HeightStyle := ssAuto;
    messageBlock.WidthStyle := ssAuto;
    messageBlock.ChildOrder := 6;
    messageBlock.ElementPosition := epIgnore;
    messageBlock.ElementFont := efCSS;
    messageBlock.Role := '';
    messageBlock.Visible := False;
  finally
    firstName.AfterLoadDFMValues;
    lastName.AfterLoadDFMValues;
    emailAddress.AfterLoadDFMValues;
    mobileNumber.AfterLoadDFMValues;
    enrolButton.AfterLoadDFMValues;
    company.AfterLoadDFMValues;
    messageBlock.AfterLoadDFMValues;
  end;
end;

end.
