program PasskitEnrolment;

uses
  Vcl.Forms,
  WEBLib.Forms,
  Enrolment in 'Enrolment.pas'{*.html},
  mainDataModule in 'mainDataModule.pas',
  thankYou in 'thankYou.pas'{*.html},
  SMX.Web.Document.Utils in 'C:\SMXi Libraries\Web-Core-Utils\SMX.Web.Document.Utils.pas',
  SMX.Web.Utils in 'C:\SMXi Libraries\Web-Core-Utils\SMX.Web.Utils.pas';

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateFormDirect(TMainData, MainData);
  Application.CreateForm(TEnrolmentForm, EnrolmentForm);
  Application.Run;
end.
